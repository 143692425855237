import React, { useState } from "react";
import PaginationDoubleArrowLeft from "../Assets/Icons/PaginationDoubleArrowLeft";
import PaginationSingleArrowLeft from "../Assets/Icons/PaginationSingleArrowLeft";
import PaginationDoubleArrowRight from "../Assets/Icons/PaginationDoubleArrowRight";
import PaginationSingleArrowRight from "../Assets/Icons/PaginationSingleArrowRight";

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pagesPerGroup = 10;
  const currentGroup = Math.ceil(currentPage / pagesPerGroup);
  const startPage = (currentGroup - 1) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="Pagination_CT">
      {
        <button onClick={() => handlePageChange(Math.max(startPage - pagesPerGroup, 1))} disabled={startPage === 1} className={"Pagination_ArrowButton_CT"}>
          <PaginationDoubleArrowLeft color={startPage === 1 ? "rgb(170, 170, 170)" : "rgb(70, 70, 70)"} />
        </button>
      }
      <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage === 1} className={"Pagination_ArrowButton_CT"}>
        <PaginationSingleArrowLeft color={currentPage === 1 ? "rgb(170, 170, 170)" : "rgb(70, 70, 70)"} />
      </button>

      {pages.map((page) => (
        <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "Pagination_ActivePage_Copy" : "Pagination_InactivePage_Copy"}>
          {page}
        </button>
      ))}

      <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage === totalPages} className={"Pagination_ArrowButton_CT"}>
        <PaginationSingleArrowRight color={currentPage === totalPages ? "rgb(170, 170, 170)" : "rgb(70, 70, 70)"} />
      </button>
      <button onClick={() => handlePageChange(Math.min(endPage + 1, totalPages))} disabled={endPage === totalPages} className={"Pagination_ArrowButton_CT"}>
        <PaginationDoubleArrowRight color={endPage === totalPages ? "rgb(170, 170, 170)" : "rgb(70, 70, 70)"} />
      </button>
    </div>
  );
};

export default Pagination;
