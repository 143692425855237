import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";

import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const LoadingData = () => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="Issue_Seal_Modal_CT">
        <PulseLoader
          color="#94BAFD"
          size={10}
          margin={4}
          loading={true}
          speedMultiplier={1}
        />
      </div>
      <div className="LadingData_Copy">
        발급을 진행 중입니다. 발급은 30초에서 1분 정도 소요됩니다.
      </div>
    </div>
  );
};

export default LoadingData;
