const ChevronRight = ({ color }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.1925 23.9885L18.2271 14.9846C18.0554 14.8385 17.9714 14.6616 17.9752 14.4539C17.9791 14.2462 18.0669 14.0692 18.2387 13.9231C18.4105 13.7513 18.5957 13.6654 18.7945 13.6654C18.9932 13.6654 19.1784 13.7513 19.3502 13.9231L28.4425 23.0154C28.5836 23.1564 28.6938 23.3038 28.7733 23.4577C28.8528 23.6115 28.8925 23.7885 28.8925 23.9885C28.8925 24.1628 28.8528 24.3333 28.7733 24.5C28.6938 24.6667 28.5836 24.8205 28.4425 24.9616L19.3387 34.0654C19.1925 34.2115 19.0156 34.2891 18.8079 34.2981C18.6002 34.3071 18.4105 34.2257 18.2387 34.0539C18.0669 33.8821 17.981 33.6968 17.981 33.4981C17.981 33.2994 18.0669 33.1141 18.2387 32.9423L27.1925 23.9885Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default ChevronRight;
