import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { requestListStore } from "../State/requestListStore";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loading from "../Components/Loading";
import RequestList from "../Components/RequestList";
import Footer from "../Components/Footer";
import Pagination from "../Components/Pagination";

const AD02RequestList = observer(() => {
  const location = useLocation();
  const { companyId, companyPassword } = location.state || {};
  const screenWidth = window.innerWidth;
  const [isLoading, setIsLoading] = useState(false);
  const requestListData = requestListStore.requestData;
  const itemsPerPage = 15;
  const [currentItems, setCurrentItems] = useState(requestListData.slice(0, itemsPerPage));

  const fetchData = async () => {
    try {
      const adminEndPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin";
      setIsLoading(true);
      const response = await axios.get(adminEndPoint, {
        withCredentials: true,
      });

      const requestListdata = response.data.data;

      if (Array.isArray(requestListdata)) {
        const modifiedArray = requestListdata?.map((item) => {
          if (item.LoseDate && item.LoseDate.length > 0) {
            const currentDate = new Date(item.LoseDate.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
            currentDate.setDate(currentDate.getDate() - 1);
            const modifiedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, "");
            const yearPartLose = modifiedDate.slice(0, 4);
            const monthPartLose = modifiedDate.slice(4, 6);
            const dayPartLose = modifiedDate.slice(6, 8);
            const formattedStringLose = `${yearPartLose}년 ${monthPartLose}월 ${dayPartLose}일`;
            const yearPartAcq = item.AcqDate.slice(0, 4);
            const monthPartAcq = item.AcqDate.slice(4, 6);
            const dayPartAcq = item.AcqDate.slice(6, 8);
            const formattedStringAcq = `${yearPartAcq}년 ${monthPartAcq}월 ${dayPartAcq}일`;
            return {
              ...item,
              LoseDate: formattedStringLose,
              AcqDate: formattedStringAcq,
            };
          } else {
            const yearPartAcq = item.AcqDate.slice(0, 4);
            const monthPartAcq = item.AcqDate.slice(4, 6);
            const dayPartAcq = item.AcqDate.slice(6, 8);
            const formattedStringAcq = `${yearPartAcq}년 ${monthPartAcq}월 ${dayPartAcq}일`;
            return { ...item, AcqDate: formattedStringAcq };
          }
        });
        console.log("어드민 요청 리스트 : ", modifiedArray);
        requestListStore.setRequestData(modifiedArray);
        setCurrentItems(modifiedArray.slice(0, itemsPerPage));
        setIsLoading(false);
      }
    } catch (error) {
      console.error("AD02RequestList - fetchData ERROR :", error);
    }
  };

  useEffect(() => {
    if (companyId === "certedadmin" && companyPassword === "20240101") {
      fetchData().catch((error) => {
        console.log("AD02RequestList - fetchData ERROR :", error);
      });
    } else {
      return null;
    }
  }, [companyId, companyPassword]);

  const handlePageChange = (page) => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentItems(requestListData.slice(start, end));
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="AllContent_Between">
        <div className="RequestList_CT">
          <div className="RequestList_Label_CT">
            <div className="RequestList_Labels_Col_CT">
              <div className="RequestList_Labels_CT">
                <div className="RequestList_Label_Name">
                  <div className="RequestList_Label_Copy">요청자</div>
                </div>
                <div className="RequestList_Label_Date">
                  <div className="RequestList_Label_Copy">요청 일자</div>
                </div>
                <div className="RequestList_Label_Type">
                  <div className="RequestList_Label_Copy">유형</div>
                </div>
                <div className="RequestList_Label_CompanyName">
                  <div className="RequestList_Label_Copy">기업/기관명</div>
                </div>
                <div className="RequestList_Label_Email">
                  <div className="RequestList_Label_Copy">기업/기관 이메일</div>
                </div>
                <div className="RequestList_Label_TelNumber">
                  <div className="RequestList_Label_Copy">기업/기관 전화번호</div>
                </div>
                <div className="RequestList_Label_Status">
                  <div className="RequestList_Label_Copy">진행 상태</div>
                </div>
                <div className="RequestList_Label_Count">
                  <div className="RequestList_Label_Copy">연락 회차</div>
                </div>
              </div>
              <div className="RequestList_Label_Divider" />
            </div>
            {currentItems.map((item) => (
              <React.Fragment key={item.ID}>
                <RequestList
                  address={item.Address || ""}
                  name={item.Name}
                  birthday={item.Birthday}
                  date={item.IssuanceRequestDate}
                  type={item.LoseDate.length > 0 ? "경력" : "재직"}
                  companyName={item.IssuanceRequestCompanyName}
                  representativeName={item?.RepresentativeName}
                  email={item.Email}
                  compEmail={item.IssuanceRequestCompanyEmail}
                  telNumber={item.IssuanceRequestCompanyPhoneNumber}
                  status={item.AuthStatus}
                  seal={item?.Seal}
                  resist={item?.BusinessRegistration}
                  firmNm={item.FirmNm}
                  position={item.Position}
                  department={item.Department}
                  jobPosition={item.JobPosition}
                  jobList={item?.JobList}
                  id={item.ID}
                  acqDate={item.AcqDate}
                  loseDate={item.LoseDate}
                  rejectReason={item?.RejectionReason}
                  delayReason={item?.DelayReason}
                  uid={item.Uid}
                  requestListData={requestListData}
                  contactTime={item?.AdminContactTime || 0}
                  note={item?.AdminNote || ""}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </React.Fragment>
            ))}
          </div>
          <div className="Wait_Pagination">
            <Pagination totalItems={requestListData.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
          </div>
        </div>
        {screenWidth > 1023 ? <Footer /> : null}
      </div>
    </>
  );
});

export default AD02RequestList;
