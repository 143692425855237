import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
import CertificatePDF from "../Components/CertificatePDF";
import EmailProfile from "../Components/EmailProfile";
import * as amplitude from "@amplitude/analytics-browser";

const RQ06RequestRejectComplete = observer(() => {
  const location = useLocation();
  const {
    reason,
    id,
    name,
    birthday,
    address,
    workDate,
    companyName,
    telNumber,
    position,
    department,
    jobPosition,
    jobList,
    requestDate,
    requestNumber,
    email,
    uid,
    ctfId,
    loseDate,
  } = location.state || {};
  const { state } = useLocation();
  console.log(state.reason);
  const navigate = useNavigate();

  const screenWidth = window.innerWidth;
  const [isEmailSend, setIsEmailSend] = useState(false);
  const onClickEmailSend = async () => {
    amplitude.track("증명서 직접 전달 안내 모달 확인");
    setIsEmailSend(true);
  };
  const onClickEmailConfirm = async () => {
    amplitude.track("증명서 직접 전달 안내 모달 내에서 사이트로 이동");
    setIsEmailSend(false);
    window.location.href = "https://www.certifie.io";
  };
  return (
    <>
      {isEmailSend && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">{name}님께 경력증명서를 전송해주세요.</div>
              </div>
              <button className="Issue_Error_Button" onClick={onClickEmailConfirm}>
                <div className="Email_Right_Button_BG" style={{ marginLeft: "0px" }}>
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {screenWidth > 1023 ? (
        <div className="AllContent_Between">
          <div className="Email_CT">
            <div className="Email_LeftArea_CT">
              <div className="Email_LeftContents_CT">
                <div className="Email_Main_Copy_CT">
                  <div className="Email_Main_1_Copy_CT">
                    <div className="Email_Main_2_Copy">{name}님</div>
                    <div className="Email_Main_1_Copy">의 경력증명서 발급 요청이</div>
                  </div>
                  <div className="Email_Main_1_Copy">거절되었습니다.</div>
                </div>
                <div className="Email_Sub_Copy_CT">
                  <div className="Email_Sub_1_Copy">
                    거절된 경력증명서는 수정, 보완 후 재요청 될 수 있습니다.
                    <br />
                    귀사에서 사용중인 경력증명서가 있다면
                    <br />
                    <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>'이메일 전송'</span> 버튼을 클릭하고{" "}
                    <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>{name}님</span>께 전송해주세요.
                  </div>
                </div>

                <div className="Email_RightArea_Buttons_CT">
                  <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickEmailSend}>
                    <div
                      className="Email_Left_Button_BG"
                      style={{
                        borderColor: "rgb(231, 239, 255)",
                        backgroundColor: "rgb(231, 239, 255)",
                        color: "rgb(55, 105, 252)",
                      }}
                    >
                      이메일 전송
                    </div>
                  </button>
                  <button
                    className="Email_Button"
                    style={{ marginTop: "0px" }}
                    onClick={() => {
                      amplitude.track("증명서 발급 거절 완료 페이지에서 발급 진행 페이지로 이동");
                      navigate(`/email/${id}/issue`, {
                        state: {
                          id: id,
                          name: name,
                          birthday: birthday,
                          address: address,
                          telNumber: telNumber,
                          workDate: workDate,
                          companyName: companyName,
                          position: position,
                          department: department,
                          jobPosition: jobPosition,
                          jobList: jobList,
                          requestDate: requestDate,
                          requestNumber: requestNumber,
                          email: email,
                          uid: uid,
                          ctfId: ctfId,
                        },
                      });
                    }}
                  >
                    <div className="Email_Right_Button_BG">다시 발급 진행</div>
                  </button>
                </div>
              </div>
              <EmailProfile />
            </div>
            <div className="Email_RightArea_CT">
              <CertificatePDF
                name={name}
                birthday={birthday}
                address={address}
                workDate={workDate}
                companyName={companyName}
                telNumber={telNumber}
                position={position}
                department={department}
                jobPosition={jobPosition}
                jobList={jobList}
                requestDate={requestDate}
                requestNumber={requestNumber}
                loseDate={loseDate}
              />
              <div className="Email_InfoBox_BG_CT">
                <div
                  className="Email_InfoBox_BG"
                  style={{
                    backgroundColor: "rgb(242, 244, 246)",
                    width: "420px",
                  }}
                >
                  <div className="Email_InfoBox_1_Copy_CT">
                    <div className="Email_InfoBox_1_Copy" style={{ color: "rgb(70, 70, 70)" }}>
                      거절 사유
                    </div>
                  </div>
                  <div className="Email_InfoBox_2_Copy">{reason}</div>
                </div>
              </div>
            </div>
          </div>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="AllContent_Between_Detail">
          <div className="Email_CT">
            <div className="Email_LeftArea_CT">
              <div className="Email_LeftContents_CT">
                <div className="Email_Main_Copy_CT">
                  <div className="Email_Main_1_Copy_CT">
                    <div className="Email_Main_1_Copy" style={{ width: "330px" }}>
                      <span
                        style={{
                          color: "rgb(55,105,252)",
                          fontWeight: "600",
                        }}
                      >
                        {name}님
                      </span>
                      의 경력증명서 발급 요청이 거절되었습니다.
                    </div>
                  </div>
                </div>
                <div className="Email_Sub_Copy_CT">
                  <div className="Email_Sub_1_Copy">
                    거절된 경력증명서는 수정, 보완 후 재요청 될 수 있습니다. 귀사에서 사용중인 경력증명서가 있다면
                    <br />
                    <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>'이메일 전송'</span> 버튼을 클릭하고{" "}
                    <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>{name}님</span>께 전송해주세요.
                  </div>
                </div>
              </div>
            </div>
            <div className="Email_RightArea_CT" style={{ marginTop: "0px" }}>
              <CertificatePDF
                name={name}
                birthday={birthday}
                address={address}
                workDate={workDate}
                companyName={companyName}
                telNumber={telNumber}
                position={position}
                department={department}
                jobPosition={jobPosition}
                jobList={jobList}
                requestDate={requestDate}
                requestNumber={requestNumber}
                loseDate={loseDate}
              />
              <div className="Email_InfoBox_BG_CT2">
                <div
                  className="Email_InfoBox_BG"
                  style={{
                    backgroundColor: "rgb(242, 244, 246)",
                  }}
                >
                  <div className="Email_InfoBox_1_Copy_CT">
                    <div className="Email_InfoBox_1_Copy" style={{ color: "rgb(70, 70, 70)" }}>
                      거절 사유
                    </div>
                  </div>
                  <div className="Email_InfoBox_2_Copy">{reason}</div>
                </div>
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickEmailSend}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    이메일 전송
                  </div>
                </button>
                <button
                  className="Email_Button"
                  style={{ marginTop: "0px" }}
                  onClick={() => {
                    amplitude.track("증명서 발급 거절 완료 페이지에서 발급 진행 페이지로 이동");
                    navigate(`/email/${id}/issue`, {
                      state: {
                        id: id,
                        name: name,
                        birthday: birthday,
                        address: address,
                        telNumber: telNumber,
                        workDate: workDate,
                        companyName: companyName,
                        position: position,
                        department: department,
                        jobPosition: jobPosition,
                        jobList: jobList,
                        requestDate: requestDate,
                        requestNumber: requestNumber,
                        email: email,
                        uid: uid,
                        ctfId: ctfId,
                      },
                    });
                  }}
                >
                  <div className="Email_Right_Button_BG">다시 발급 진행</div>
                </button>
              </div>
              <EmailProfile />
            </div>
          </div>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
});

export default RQ06RequestRejectComplete;
