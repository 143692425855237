const ChevronLeft = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6078 23.9885L28.6117 32.9539C28.7578 33.1001 28.829 33.277 28.8251 33.4846C28.8213 33.6923 28.7335 33.8821 28.5617 34.0539C28.3899 34.2257 28.2046 34.3116 28.0059 34.3116C27.8072 34.3116 27.6219 34.2257 27.4501 34.0539L18.3578 24.9616C18.2168 24.8205 18.113 24.6667 18.0463 24.5C17.9796 24.3334 17.9463 24.1628 17.9463 23.9885C17.9463 23.7885 17.9796 23.6116 18.0463 23.4577C18.113 23.3039 18.2168 23.1564 18.3578 23.0154L27.4617 13.8731C27.6078 13.7269 27.7931 13.6475 28.0174 13.6346C28.2418 13.6218 28.4399 13.7013 28.6117 13.8731C28.7578 14.0449 28.8309 14.2365 28.8309 14.4481C28.8309 14.6596 28.7578 14.8385 28.6117 14.9846L19.6078 23.9885Z"
        fill="#464646"
      />
    </svg>
  );
};

export default ChevronLeft;
