import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import ChevronRight from "../Assets/Icons/chevronRight";
import CheckBoxFill from "../Assets/Icons/CheckBoxFill";
import CheckBoxBlank from "../Assets/Icons/CheckBoxBlank";

const TermList = ({
  required,
  optional,
  twoLine,
  bodyText,
  detail,
  detailLink,
  isCheckBox,
  setIsCheckBox,
  checkAll,
  checkAllHandler,
  black,
  blue,
  shortWidth,
  issue,
  margin,
}) => {
  const onPressCheckBox = () => {
    setIsCheckBox(!isCheckBox);

    if (checkAll) {
      checkAllHandler();
    }
  };

  const openDetailLinkInNewTab = () => {
    window.open(detailLink, "_blank");
  };

  return (
    <div
      className={
        twoLine && shortWidth && !issue
          ? "TermList_Two_Short_CT"
          : twoLine && !shortWidth && !issue
          ? "TermList_Two_CT"
          : !twoLine && shortWidth && !issue
          ? "TermList_Short_CT"
          : twoLine && shortWidth && issue
          ? "TermList_Two_Short_CT_Issue"
          : twoLine && !shortWidth && issue
          ? "TermList_Two_CT_Issue"
          : !twoLine && shortWidth && issue
          ? "TermList_Short_CT_Issue"
          : "TermList_CT"
      }
      style={{ marginTop: `${margin || 0}`, marginBottom: `${margin || 0}` }}
    >
      <div className="TermList_Left">
        {required && <div className="TermList_Red_Copy">[필수]</div>}
        {optional && <div className="TermList_Optional_Copy">[선택]</div>}
        {twoLine ? (
          <div
            className="TermList_TwoLine_Copy"
            style={{
              color: black
                ? "rgb(70, 70, 70)"
                : blue
                ? "rgb(55, 105, 252)"
                : null,
              fontWeight: blue ? 700 : null,
            }}
          >
            {bodyText}
          </div>
        ) : (
          <div
            className="TermList_Default_Copy"
            style={{
              color: black ? "rgb(70, 70, 70)" : null,
              fontSize: issue ? "12px" : null,
            }}
          >
            {bodyText}
          </div>
        )}
      </div>
      <div className="TermList_Right">
        {detail && (
          <button
            type="button"
            className="TermList_Detail_CT"
            onClick={openDetailLinkInNewTab}
          >
            <div className="TermList_Detail_Copy">내용보기</div>
            <div className="TermList_Detail_Icon ">
              <ChevronRight />
            </div>
          </button>
        )}
        <button
          type="button"
          className="TermList_CheckBox_CT"
          onClick={onPressCheckBox}
        >
          {isCheckBox === true ? <CheckBoxFill /> : <CheckBoxBlank />}
        </button>
      </div>
    </div>
  );
};

export default TermList;
