import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";

const AD01Login = observer(() => {
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;

  const [companyId, setCompanyId] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const testToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55TmFtZSI6Iuq0keynhOyLne2SiCIsImNvbXBhbnlOdW1iZXIiOiIwMTA1MTg0Mjg2NiIsImVtcGxveW1lbnRQZXJpb2QiOiIyMDE564WEIDAx7JuUIDAy7J28IH4gMjAxOeuFhCAwMeyblCAzMOydvCIsInBvc2l0aW9uIjoi65ah65Sc66as67KE66asIiwiZGVwYXJ0bWVudCI6IuuWoe2MgCIsImpvYiI6W3siam9iIjoi65ahIO2PrOyepSIsImlkIjoiN2MwZGZjNDYtZTdmZC00OThjLTkwZTQtYzgyZTRlZTI3Yjc4In1dLCJqb2JQb3NpdGlvbiI6IuyVhOultOuwlOydtO2KuCIsImJpcnRoZGF5IjoiMTk5NeuFhCAxMeyblCAwMuydvCIsImFkZHJlc3MiOiIsIiwibmFtZSI6IuqzoO2VnOykgCIsImVtYWlsIjoiYXByaWwwNzAyQG5hdmVyLmNvbSIsInVzZXJJZCI6IjExMzYzMWEzLTU3MGMtNGFjOC1iMzI2LTM5MGE0NzI0YjQzYSIsImN0ZklkIjoiMGRkZGJjZjQtMDJhNy00YjE4LTg1MmMtMWMwM2MwNjIyYjFlIiwiaWF0IjoxNzI0NjMzNTk1LCJleHAiOjE3MjUyMzgzOTV9.sYJEj8IZMUClFeIOf3il63DrmXgHEL_q5cGgZ_EB3_M";
  const handleLoginComplete = async () => {
    if (companyId === "certedadmin" && companyPassword === "20240101") {
      navigate("/requestlist/admin", {
        state: { companyId: companyId, companyPassword: companyPassword },
      });
    }
  };

  const handleLoginCompleteMobile = async () => {
    if (companyId === "certedadmin" && companyPassword === "20240101") {
      navigate("/requestlist/admin/M", {
        state: { companyId: companyId, companyPassword: companyPassword },
      });
    } else {
      alert("아이디 또는 비밀번호가 맞지 않습니다.\n\n다시 한번 확인해주세요.");
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const sealTest = async () => {
  //   navigate(`/email/info/${testToken}`, {
  //     state: { token: testToken },
  //   });
  // };

  return (
    <>
      <div className="FlexContent_Login">
        <div className="AllContent">
          <div className="Login_Head_Copy">관리자 로그인</div>
          <input
            className="Login_Input_BG"
            placeholder="아이디를 입력 해주세요."
            value={companyId}
            style={{ marginBottom: windowWidth > 767 ? "12px" : "2px" }}
            onChange={(e) => setCompanyId(e.target.value)}
            required
          />
          <input
            className="Login_Input_BG"
            placeholder="비밀번호를 입력 해주세요."
            value={companyPassword}
            onChange={(e) => setCompanyPassword(e.target.value)}
            required
            type="password"
          />
          <div className="Login_Button_One_CT">
            <button className="LoginButton_Copy_CT" onClick={windowWidth > 767 ? handleLoginComplete : handleLoginCompleteMobile}>
              <div className="LoginButton_One_BG">
                <div className="LoginButton_Copy">로그인</div>
              </div>
            </button>
          </div>

          {/* <div className="Login_Button_One_CT">
            <button className="LoginButton_Copy_CT" onClick={sealTest}>
              <div className="LoginButton_One_BG">
                <div className="LoginButton_Copy">이메일 테스트</div>
              </div>
            </button>
          </div> */}
        </div>
        {screenWidth > 1023 && <Footer />}
      </div>
    </>
  );
});

export default AD01Login;
