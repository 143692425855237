import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../Components/Footer";
import Block from "../Assets/Icons/Block";
import Loading from "../Components/Loading";
import ReceiveCertificationItem from "../Components/ReceiveCertificationItem";
import { ConvertAcqDateString, ConvertLoseDateString } from "../Utils/ConvertNumberData";

const EM01ReceiveCertification = observer(() => {
  const screenWidth = window.innerWidth;
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [sendList, setSendList] = useState([]);
  const [isBlock, setIsBlock] = useState(null);
  const [userName, setUserName] = useState(null);
  const [resumeLink, setResumeLink] = useState("");

  const HIQCTitle = async (item) => {
    if (item?.employmentData.length > 0) {
      return `${item?.employmentData[0]?.FirmNm} 외 ${item?.employmentData.length - 1}건`;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const HIQCDate = async (item) => {
      if (item?.birthday && item?.birthday.length === 8) {
        const sixDigitString = item?.birthday.substring(2);
        return `비밀번호 : ${sixDigitString}`;
      } else if (item?.AcqDate) {
        return `${ConvertAcqDateString(item.AcqDate)} - ${ConvertLoseDateString(item.LoseDate)}`;
      }
    };

    const laodRequestData = async () => {
      const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/career-plural";
      setIsLoading(true);
      try {
        const certificateResponse = await axios.get(endPoint, {
          params: { token: token },
        });
        if (certificateResponse?.data?.data?.resume) {
          setResumeLink(certificateResponse.data.data.resume);
        }
        const titles = await Promise.all(
          certificateResponse.data.hiqcData.map(async (item) => {
            return item.birthday ? await HIQCTitle(item) : item.FirmNm;
          })
        );
        const dates = await Promise.all(
          certificateResponse.data.hiqcData.map(async (item) => {
            return HIQCDate(item);
          })
        );
        setSendList(
          certificateResponse.data.hiqcData.map((item, index) => ({
            ...item,
            title: titles[index],
            date: dates[index],
          }))
        );
        setUserName(certificateResponse.data.data.userName);
        setIsLoading(false);
      } catch (error) {
        console.log("certificate read aodRequestData error : ", error);
        if (error.response.data.status === "경력증명서 정보 조회 오류 발생") {
          setIsBlock(true);
          setIsLoading(false);
        }
      }
    };

    if (token) {
      laodRequestData();
    }
  }, [token]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="Read_AllContent_Between">
        {!isBlock ? (
          <div className="Email_CT">
            <div className="Email_LeftArea_CT">
              <div className="Email_LeftContents_CT_Reject">
                <div className="Email_Main_Copy_CT">
                  <div className="Email_Main_1_Copy_CT">
                    <div className="Email_Main_2_Copy">{userName}님</div>
                    <div className="Email_Main_1_Copy">의</div>
                  </div>
                  <div className="Email_Main_1_Copy">증명서 입니다.</div>
                </div>
                <div className="Email_Sub_Copy_CT">
                  <div className="Email_Sub_1_Copy">
                    <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>{userName}님</span>의 요청에 의해 열람이 제한 될 수 있습니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="Read_RightArea_CT">
              <div className="Read_Labels_CT">
                <div className="Read_Label_Copy">증명서 목록</div>
              </div>
              <div className="Read_Divider" />
              {resumeLink.length > 0 ? (
                <ReceiveCertificationItem
                  key={"resume"}
                  type={"이력서"}
                  title={`${userName}님의 이력서`}
                  date={null}
                  position={null}
                  department={null}
                  jobPosition={null}
                  jobList={null}
                  PDF={resumeLink}
                />
              ) : null}
              {sendList.map((item, index) => (
                <ReceiveCertificationItem
                  key={index}
                  type={item.birthday ? "건강보험자격득실확인서" : item?.LoseDate.length > 0 ? "경력증명서" : "재직증명서"}
                  title={item.title}
                  date={item.date}
                  position={item.birthday ? null : item.Position}
                  department={item.birthday ? null : item.Department}
                  jobPosition={item.birthday ? null : item.JobPosition}
                  jobList={item.birthday ? null : item.JobList}
                  PDF={item.birthday ? item.hiqcPDF : item.CareerCertificatePDF}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="Email_Block_All_CT">
            <div className="Email_Block_CT">
              <div className="Email_Block_Icon_CT">
                <Block />
              </div>
              <div className="Email_Main_1_Copy">증명서 열람 권한이 만료되었습니다.</div>
            </div>
          </div>
        )}

        {screenWidth > 1023 ? (
          <div>
            <Footer />
          </div>
        ) : null}
      </div>
    </>
  );
});

export default EM01ReceiveCertification;
