import React from "react";
import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import { useNavigate } from "react-router-dom";
import { AddDotYYYYMMDD, AddZeroToDate } from "../Utils/ConvertNumberData";

const RequestCard = ({
  name,
  date,
  type,
  companyName,
  email,
  compEmail,
  telNumber,
  status,
  seal,
  resist,
  firmNm,
  position,
  department,
  jobPosition,
  jobList,
  id,
  acqDate,
  loseDate,
  rejectReason,
  uid,
  requestListData,
  note,
  contactTime,
}) => {
  const navigate = useNavigate();
  const IssuanceDateMatch = date?.match(/\d{4}\. \d{1,2}\. \d{1,2}/);
  const formattedDate = IssuanceDateMatch ? AddDotYYYYMMDD(IssuanceDateMatch[0]) : "";
  const detailDate = AddDotYYYYMMDD(date);

  const state = {
    name: name,
    date: detailDate,
    email: email,
    type: type,
    companyName: companyName,
    compEmail: compEmail,
    telNumber: telNumber,
    status: status,
    seal: seal,
    resist: resist,
    firmNm: firmNm,
    position: position,
    department: department,
    jobPosition: jobPosition,
    jobList: jobList,
    id: id,
    acqDate: acqDate,
    loseDate: loseDate,
    rejectReason: rejectReason,
    uid: uid,
    requestListData: requestListData,
    note: note,
    contactTime: contactTime,
  };
  const clonedState = JSON.parse(JSON.stringify(state));
  const handleDetail = async () => {
    navigate("/requestlist/admin/M/detail", {
      state: clonedState,
    });
  };
  return (
    <>
      <button className="RequestCardCT_Container" onClick={() => handleDetail()}>
        <div className="RequestCardCT_Left_CT ">
          <div className="RequestCardCT_LeftTop_CT">
            <div className="RequestCardCT_Name_Copy">
              {name} | {type}
            </div>
            <div
              className="RequestCardCT_Memo_CT"
              style={{
                backgroundColor: note.length > 0 ? "rgb(55,105,252)" : null,
                borderColor: note.length > 0 ? "rgb(55,105,252)" : null,
              }}
            >
              <div
                className="RequestCardCT_Memo_Copy"
                style={{
                  color: note.length > 0 ? "rgb(255,255,255)" : "rgb(70,70,70)",
                }}
              >
                메모
              </div>
            </div>
          </div>
          <div className="RequestCardCT_LeftBottom_CT">
            <div className="RequestCardCT_CompName_Copy">{companyName}</div>
          </div>
        </div>
        <div className="RequestCardCT_Right_CT ">
          <div className="RequestCardCT_LeftTop_CT">
            <div
              className="RequestList_Body_Status"
              style={{
                backgroundColor:
                  status === "발급 대기"
                    ? "rgb(170,170,170)"
                    : status === "발급 진행"
                    ? "rgb(255,146,18)"
                    : status === "발급 승인"
                    ? "rgb(29,201,26)"
                    : status === "발급 완료"
                    ? "rgb(55,105,252)"
                    : status === "발급 거절"
                    ? "rgb(245,69,69)"
                    : status === "직접 대기"
                    ? "rgb(170,170,170)"
                    : status === "직접 완료"
                    ? "rgb(55,105,252)"
                    : status === "직접 지연"
                    ? "rgb(245,69,69)"
                    : "rgb(70,70,70)",
              }}
            >
              <div className="RequestList_Body_Copy" style={{ color: "white", fontWeight: "600" }}>
                {status === "발급 대기"
                  ? "대기"
                  : status === "발급 진행"
                  ? "진행"
                  : status === "발급 승인"
                  ? "승인"
                  : status === "발급 거절"
                  ? "거절"
                  : status === "발급 완료"
                  ? "완료"
                  : status}
              </div>
            </div>
          </div>
          <div className="RequestCardCT_LeftBottom_CT">
            <div className="RequestCardCT_Date_Copy">{AddZeroToDate(formattedDate)}</div>
          </div>
        </div>
      </button>
    </>
  );
};

export default RequestCard;
