const CheckBoxFill = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94232 20.5C4.54872 20.5 4.20994 20.358 3.92597 20.074C3.64199 19.79 3.5 19.4513 3.5 19.0577V4.94236C3.5 4.54876 3.64199 4.20997 3.92597 3.92601C4.20994 3.64202 4.54872 3.50003 4.94232 3.50003H19.0576C19.4512 3.50003 19.79 3.64202 20.074 3.92601C20.358 4.20997 20.5 4.54876 20.5 4.94236V19.0577C20.5 19.4513 20.358 19.79 20.074 20.074C19.79 20.358 19.4512 20.5 19.0576 20.5H4.94232ZM10.5135 15.4058C10.607 15.4058 10.6981 15.3875 10.7865 15.351C10.875 15.3144 10.9493 15.2577 11.0096 15.1808L16.9634 9.21153C17.075 9.1 17.1307 8.96987 17.1307 8.82116C17.1307 8.67246 17.075 8.534 16.9634 8.40578C16.8455 8.2981 16.708 8.24426 16.5509 8.24426C16.3939 8.24426 16.2596 8.30323 16.1481 8.42118L10.5135 14.0558L7.95765 11.5096C7.84612 11.3981 7.716 11.3423 7.5673 11.3423C7.41858 11.3423 7.28525 11.3981 7.1673 11.5096C7.04935 11.6276 6.99038 11.7676 6.99038 11.9298C6.99038 12.092 7.04935 12.2269 7.1673 12.3346L10.0077 15.1808C10.0846 15.2577 10.1657 15.3144 10.251 15.351C10.3362 15.3875 10.4237 15.4058 10.5135 15.4058Z"
        fill="#3769FC"
      />
    </svg>
  );
};

export default CheckBoxFill;
