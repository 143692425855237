import React from "react";
import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";

const ReceiveCertificationItem = ({ type, title, date, position, department, jobPosition, jobList, PDF }) => {
  const onClickPDFOpen = async () => {
    if (PDF?.length > 0) {
      window.open(PDF, "_blank", "noopener noreferrer");
    } else {
      return null;
    }
  };
  return (
    <div className="Read_All_Content">
      <div className="Read_Content">
        <div className="Read_Item_Text_CT">
          <div className="Read_Item_Type_Copy">{type}</div>
          <div className="Read_Item_Name_Copy">{title}</div>
          <div className="Read_Item_Date_Copy">{date}</div>
          {position && department && jobPosition ? (
            <div className="Read_Item_Positions_Copy">
              {position} ({department} / {jobPosition})
            </div>
          ) : null}
          {jobList && jobList[0] ? <div className="Read_Item_Jobs_Copy">- {jobList[0]?.Job}</div> : null}
          {jobList && jobList[1] ? <div className="Read_Item_Jobs_Copy">- {jobList[1]?.Job}</div> : null}
          {jobList && jobList[2] ? <div className="Read_Item_Jobs_Copy">- {jobList[2]?.Job}</div> : null}
          {jobList && jobList[3] ? <div className="Read_Item_Jobs_Copy">- {jobList[3]?.Job}</div> : null}
          {jobList && jobList[4] ? <div className="Read_Item_Jobs_Copy">- {jobList[4]?.Job}</div> : null}
        </div>

        <button className="Read_Button" type="button" onClick={onClickPDFOpen}>
          <div className="Read_Right_Button_Short_BG" style={{ marginLeft: "0px" }}>
            PDF 확인
          </div>
        </button>
      </div>
      <div className="Read_Item_Divider" />
    </div>
  );
};

export default ReceiveCertificationItem;
