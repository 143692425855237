import React from "react";
import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
// import Close from "../Assets/Icons/Close";
import * as amplitude from "@amplitude/analytics-browser";
const CertificatePDF = ({
  name,
  birthday,
  address,
  workDate,
  companyName,
  telNumber,
  position,
  department,
  jobPosition,
  jobList,
  requestDate,
  issue,
  companyHeadName,
  setCompanyHeadName,
  // sealImageUrl,
  // onClickDeleteSealImage,
  // onClickSealImageUpload,
  loseDate,
  // mobile,
}) => {
  return (
    <div className="Email_PDF_BG">
      <div className="Email_PDF_Upper">
        <div className="Email_PDF_IssueNumber_Copy">발급번호 :</div>
        {loseDate.length > 0 ? <div className="Email_PDF_Title_Copy">경 력 증 명 서</div> : <div className="Email_PDF_Title_Copy">재 직 증 명 서</div>}

        {jobList[0]?.job && jobList[0]?.job.length > 0 ? (
          <table
            className="Email_PDF_Chart_BG"
            style={{
              borderSpacing: "0px",
              borderCollapse: "collapse",
            }}
          >
            <tbody>
              <tr>
                <td className="Email_PDF_Cell_Label" rowSpan="2">
                  인적사항
                </td>
                <td className="Email_PDF_Cell_Label_2">성 명</td>
                <td className="Email_PDF_Cell_Body">{name}</td>
                <td className="Email_PDF_Cell_Label">생년월일</td>
                <td className="Email_PDF_Cell_Body">{birthday}</td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label_2">주 소</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {address}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label" rowSpan="6">
                  재직사항
                </td>
                <td className="Email_PDF_Cell_Label_3">근 무 처</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {companyName}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label">전화번호</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {telNumber}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label">근무기간</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {workDate}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label_3">포 지 션</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {position}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label_2">부 서</td>
                <td className="Email_PDF_Cell_Body">{department}</td>
                <td className="Email_PDF_Cell_Label_2">직 위</td>
                <td className="Email_PDF_Cell_Body">{jobPosition}</td>
              </tr>

              <tr>
                <td className="Email_PDF_Cell_Label_2">직 무</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {jobList.length > 0 && <>- {jobList[0]?.job}</>}
                  {jobList.length > 1 && (
                    <>
                      <br />- {jobList[1]?.job}
                    </>
                  )}
                  {jobList.length > 2 && (
                    <>
                      <br />- {jobList[2]?.job}
                    </>
                  )}
                  {jobList.length > 3 && (
                    <>
                      <br />- {jobList[3]?.job}
                    </>
                  )}
                  {jobList.length > 4 && (
                    <>
                      <br />- {jobList[4]?.job}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table
            className="Email_PDF_Chart_BG"
            style={{
              borderSpacing: "0px",
              borderCollapse: "collapse",
            }}
          >
            <tbody>
              <tr>
                <td className="Email_PDF_Cell_Label" rowSpan="2">
                  인적사항
                </td>
                <td className="Email_PDF_Cell_Label_2">성 명</td>
                <td className="Email_PDF_Cell_Body">{name}</td>
                <td className="Email_PDF_Cell_Label">생년월일</td>
                <td className="Email_PDF_Cell_Body">{birthday}</td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label_2">주 소</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {address}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label" rowSpan="6">
                  재직사항
                </td>
                <td className="Email_PDF_Cell_Label_3">근 무 처</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {companyName}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label">전화번호</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {telNumber}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label">근무기간</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {workDate}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label_3">포 지 션</td>
                <td className="Email_PDF_Cell_Body" colSpan="3">
                  {position}
                </td>
              </tr>
              <tr>
                <td className="Email_PDF_Cell_Label_2">부 서</td>
                <td className="Email_PDF_Cell_Body">{department}</td>
                <td className="Email_PDF_Cell_Label_2">직 위</td>
                <td className="Email_PDF_Cell_Body">{jobPosition}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="Email_PDF_Body_Copy">상기인은 본 직장에서 위와 같이 재직하였음을 증명합니다.</div>
        <div className="Email_PDF_Date_Copy">{requestDate}</div>
      </div>
      {issue ? (
        <>
          <div className="Email_PDF_Lower">
            <div className="Email_PDF_CompanyName_Copy">{companyName}</div>
            <div className="Email_PDF_CompanyHead_CT">
              <div className="Email_PDF_CompanyHead_Copy">대표자 :</div>
              <input
                className="Email_CompanyHead_Input_BG"
                placeholder="성함을 입력해주세요."
                value={companyHeadName}
                onChange={(e) => {
                  if (e.target.value.length >= 2) {
                    amplitude.track("대표자명 테스트 입력", {
                      대표자명: e.target.value,
                    });
                  }
                  setCompanyHeadName(e.target.value);
                }}
              />
            </div>
          </div>
          {/* {sealImageUrl ? (
            <div className={mobile ? "Email_PDF_SealDone_BG_M" : "Email_PDF_SealDone_BG"}>
              <img className="Email_PDF_Seal_Copy" src={sealImageUrl} alt="sealtImg" style={{ width: "auto", maxHeight: mobile ? "48px" : "72px" }} />
              <button type="button" className="Email_PDF_SealDone_Close_CT" onClick={onClickDeleteSealImage}>
                <Close />
              </button>
            </div>
          ) : (
            <button className={mobile ? "Email_PDF_SealNone_BG_M" : "Email_PDF_SealNone_BG"} onClick={onClickSealImageUpload} type="button">
              <div className="Email_PDF_Seal_Copy">
                직인
                <br />
                추가
              </div>
            </button>
          )} */}
        </>
      ) : (
        <>
          <div className="Email_PDF_Lower">
            <div className="Email_PDF_CompanyName_Copy">{companyName}</div>
            <div className="Email_PDF_CompanyHead_CT">
              <div className="Email_PDF_CompanyHead_Copy">대표자 :</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CertificatePDF;
