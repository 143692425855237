import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { requestListStore } from "../State/requestListStore";
import { useLocation } from "react-router-dom";
import Remove from "../Assets/Icons/Remove";
import Add from "../Assets/Icons/Add";
import CancelNone from "../Assets/Icons/CancelNone";
import SelfIssue from "../Assets/Icons/SelfIssue";
import ConfirmNone from "../Assets/Icons/ConfirmNone";
import TermList from "../Components/TermList";
import RequestResend from "../Assets/Icons/RequestResend";
import Loading from "../Components/Loading";
import { AddZeroToDate } from "../Utils/ConvertNumberData";

const AD03RequestDetailMobile = observer(() => {
  const location = useLocation();
  const { state: clonedState } = location;
  const {
    name,
    date,
    email,
    type,
    companyName,
    compEmail,
    telNumber,
    status,
    seal,
    resist,
    firmNm,
    position,
    department,
    jobPosition,
    jobList,
    id,
    acqDate,
    loseDate,
    rejectReason,
    uid,
    requestListData,
    representativeName,
    note,
    contactTime,
  } = clonedState || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isSealVisible, setIsSealVisible] = useState(false);
  const [isItemDataVisible, setIsItemDataVisible] = useState(false);
  const [isMemoVisible, setIsMemoVisible] = useState(false);
  const [isRejectVisible, setIsRejectVisible] = useState(false);
  const [isRejectReasonVisible, setIsRejectReasonVisible] = useState(false);
  const [isRadioAllCheck, setIsRadioAllCheck] = useState(false);
  const [isRadio1Check, setIsRadio1Check] = useState(false);
  const [isRadio2Check, setIsRadio2Check] = useState(false);
  const [isRadio3Check, setIsRadio3Check] = useState(false);
  const [isRadio4Check, setIsRadio4Check] = useState(false);
  const [isRadioCheckDone, setIsRadioCheckDone] = useState(false);
  const [etcRejectReason, setEtcRejectReason] = useState("");
  const [isSelfIssueVisible, setIsSelfIssueVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [memo, setMemo] = useState(note || "");
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [newCompEmail, setNewCompEmail] = useState(compEmail);
  const checkAllHandler = () => {
    if (!isRadioAllCheck) {
      setIsRadio1Check(true);
      setIsRadio2Check(true);
      setIsRadio3Check(true);
      setIsRadio4Check(true);
    } else {
      setIsRadio1Check(false);
      setIsRadio2Check(false);
      setIsRadio3Check(false);
      setIsRadio4Check(false);
    }
  };

  const currentDate = new Date();
  const resendDate = currentDate.toLocaleString();
  const handleNewCompEmailChange = (event) => {
    setNewCompEmail(event.target.value);
  };
  const onClickSealOpen = async () => {
    setIsSealVisible(true);
  };

  const onClickSealClose = async () => {
    setIsSealVisible(false);
  };

  const onClickResistOpen = async () => {
    if (resist?.length > 0) {
      window.open(resist, "_blank", "noopener noreferrer");
    }
  };

  const onClickItemDataOpen = async () => {
    setIsItemDataVisible(true);
  };

  const onClickItemDataClose = async () => {
    setIsItemDataVisible(false);
  };

  const onClickConfirmOpen = async () => {
    setIsConfirmVisible(true);
  };

  const convertToFormat = (input) => (input.length === 8 ? `${input.slice(0, 4)}.${input.slice(4, 6)}.${input.slice(6, 8)}` : "현재");

  const formattedAcqDate = convertToFormat(acqDate);
  const formattedLoseDate = convertToFormat(loseDate);

  const onClickCountUp = async () => {
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin";
    try {
      const response = await axios.post(endPoint, {
        email: email,
        userId: uid,
        ctfId: id,
        data: { contactTime: contactTime > 0 ? contactTime + 1 : 1 },
      });
      if (response.data.status === "연락회차 업데이트 성공") {
        const changeContactTimeArray = requestListData.map((item) =>
          item.ID === id
            ? {
                ...item,
                AdminContactTime: contactTime > 0 ? contactTime + 1 : 1,
              }
            : { ...item }
        );
        requestListStore.setRequestData(changeContactTimeArray);
      }
    } catch (error) {
      console.error("Error during axios.get : ", error);
      alert(`연락회차 증가 완료를 실패하였습니다. 다시 시도해주세요. ${error}`);
    }
  };

  const onClickCountDown = async () => {
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin";
    try {
      const response = await axios.post(endPoint, {
        email: email,
        userId: uid,
        ctfId: id,
        data: { contactTime: contactTime > 0 ? contactTime - 1 : 0 },
      });
      if (response.data.status === "연락회차 업데이트 성공") {
        const changeContactTimeArray = requestListData.map((item) =>
          item.ID === id
            ? {
                ...item,
                AdminContactTime: contactTime > 0 ? contactTime - 1 : 0,
              }
            : { ...item }
        );
        requestListStore.setRequestData(changeContactTimeArray);
      }
    } catch (error) {
      console.error("Error during axios.get : ", error);
      alert(`연락회차 증가 감소를 실패하였습니다. 다시 시도해주세요. ${error}`);
    }
  };

  const onClickMemoOpen = async () => {
    setIsMemoVisible(true);
  };

  const onClickMemoClose = async () => {
    setIsMemoVisible(false);
  };

  const handleMemoChange = (event) => {
    setMemo(event.target.value);
  };

  const onClickMemoChange = async () => {
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin";
    try {
      const response = await axios.post(endPoint, {
        email: email,
        userId: uid,
        ctfId: id,
        data: { note: memo },
      });

      if (response.data.status === "비고 업데이트 성공") {
        const changeMemoArray = requestListData.map((item) =>
          item.ID === id
            ? {
                ...item,
                AdminNote: memo,
              }
            : { ...item }
        );
        requestListStore.setRequestData(changeMemoArray);
        onClickMemoClose();
      }
    } catch (error) {
      console.error("Error during axios.get : ", error);
      alert(`메모 저장을 실패하였습니다. 다시 시도해주세요. ${error}`);
    }
  };

  const addToResendDate = async () => {
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin";
    try {
      let existingMemo = memo;
      existingMemo += existingMemo ? `\n\n[${newCompEmail}로 발급요청 이메일 재전송 - ${resendDate}]` : `[${newCompEmail}로 발급요청 이메일 재전송 - ${resendDate}]`;
      const response = await axios.post(
        endPoint,
        {
          email: email,
          userId: uid,
          ctfId: id,
          data: { note: existingMemo },
        },
        {
          withCredentials: true,
        }
      );
      if (response.data.status === "비고 업데이트 성공") {
        const changeMemoArray = requestListData.map((item) =>
          item.ID === id
            ? {
                ...item,
                AdminNote: existingMemo,
              }
            : { ...item }
        );
        requestListStore.setRequestData(changeMemoArray);
        setMemo(existingMemo);
      }
    } catch (error) {
      console.error("Error during axios.get : ", error);
      alert(`재전송 날짜 저장을 실패하였습니다. 다시 시도해주세요. ${error}`);
    }
  };

  const onClickRejectOpen = async () => {
    setIsRejectVisible(true);
  };
  const onClickRejectClose = async () => {
    setIsRejectVisible(false);
  };

  const onClickRejectReasonOpen = async () => {
    setIsRejectVisible(false);
    setIsRejectReasonVisible(true);
  };

  const handleEtcReasonChange = (event) => {
    setEtcRejectReason(event.target.value);
  };

  const onClickRejectReasonClose = async () => {
    setIsRejectReasonVisible(false);
    setIsRadioAllCheck(false);
    setIsRadio1Check(false);
    setIsRadio2Check(false);
    setIsRadio3Check(false);
    setIsRadio4Check(false);
  };

  const onClickSelfIssueOpen = async () => {
    setIsSelfIssueVisible(true);
  };

  const onClickSelfIssueClose = async () => {
    setIsSelfIssueVisible(false);
  };

  const onClickSelfIssueConfirm = async () => {
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin";
    try {
      const response = await axios.put(endPoint, {
        email: email,
        userId: uid,
        ctfId: id,
        authStatus: "직접 대기",
        companyName: firmNm,
      });

      const itemData = JSON.parse(response.config.data);

      const itemId = itemData.ctfId;
      if (response.data.status === "authStatus 업데이트 성공") {
        const changeConfirmArray = requestListData.map((item) => (item.ID === itemId ? { ...item, AuthStatus: "직접 대기" } : { ...item }));
        requestListStore.setRequestData(changeConfirmArray);
        setIsSelfIssueVisible(false);
      } else {
        alert(`경력증명서 직접 발급 전환 실패하였습니다. 다시 시도해주세요. ${response.data.status}`);
        setIsSelfIssueVisible(false);
      }
    } catch (error) {
      console.error("Error during axios.get : ", error);
      alert(`경력증명서 직접 발급 전환 실패하였습니다. 다시 시도해주세요. ${error}`);
      setIsConfirmVisible(false);
    }
  };

  let dynamicRejectReason = "";
  if (isRadio1Check) {
    dynamicRejectReason += "- 기업/기관 이메일을 한 번 더 확인해 주세요.";
  }

  if (isRadio2Check) {
    dynamicRejectReason += dynamicRejectReason ? "\n- 기업/기관 전화번호를 한 번 더 확인해 주세요." : "- 기업/기관 전화번호를 한 번 더 확인해 주세요.";
  }

  if (isRadio3Check) {
    dynamicRejectReason += dynamicRejectReason ? "\n- 요청하신 증명서의 내용이 사실과 다른 부분이 있습니다." : "- 요청하신 증명서의 내용이 사실과 다른 부분이 있습니다.";
  }

  if (isRadio4Check) {
    dynamicRejectReason += dynamicRejectReason
      ? `
- ${companyName}과 연락이 닿지 않습니다.
해당 발급 요청이 취소되었으니 재요청을 해주시거나 ${companyName}에게 직접 발급 요청을 해주시기 바랍니다. 발급 금액은 1~2일 내에 환불 될 예정입니다.`
      : `- ${companyName}과 연락이 닿지 않습니다.
해당 발급 요청이 취소되었으니 재요청을 해주시거나 ${companyName}에게 직접 발급 요청을 해주시기 바랍니다. 발급 금액은 1~2일 내에 환불 될 예정입니다.`;
  }

  const onClickRejectConfirm = async () => {
    const rejectEndPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/reject";
    try {
      const response = await axios.get(rejectEndPoint, {
        params: {
          rejectReason: dynamicRejectReason,
          email: email,
          userId: uid,
          ctfId: id,
          companyName: companyName,
          admin: "true",
        },
      });
      if (response.data.status === "증명서 발급 거절") {
        alert("경력증명서 발급 거절을 완료하였습니다.");

        const changeRejectArray = requestListData.map((item) => (item.ID === response.data.data.ID ? { ...item, AuthStatus: "발급 거절" } : { ...item }));
        requestListStore.setRequestData(changeRejectArray);

        setIsRejectReasonVisible(false);
        setIsRadioAllCheck(false);
        setIsRadio1Check(false);
        setIsRadio2Check(false);
        setIsRadio3Check(false);
        setIsRadio4Check(false);
      } else {
        alert("경력증명서 발급 거절을 실패하였습니다. 다시 시도해주세요.");
        setIsRejectReasonVisible(false);
      }
    } catch (error) {
      alert(`경력증명서 발급 거절을 실패하였습니다. 다시 시도해주세요. ${error}`);
    }
  };

  const onClickConfirmClose = async () => {
    setIsConfirmVisible(false);
  };

  const onClickIssueConfirm = async () => {
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin/accept";
    try {
      const response = await axios.post(endPoint, {
        email: email,
        userId: uid,
        ctfId: id,
        companyName: firmNm,
      });
      if (response.data.status === "증명서 발급 최종 승인 완료") {
        alert("경력증명서 발급이 완료 되었습니다.");

        const changeConfirmArray = requestListData.map((item) => (item.ID === response.data.data.ID ? { ...item, AuthStatus: "발급 완료" } : { ...item }));
        requestListStore.setRequestData(changeConfirmArray);
        setIsConfirmVisible(false);
      } else {
        alert(`경력증명서 발급 완료를 실패하였습니다. 다시 시도해주세요. ${response.data.status}`);
        setIsConfirmVisible(false);
      }
    } catch (error) {
      console.error("Error during axios.get : ", error);
      alert(`경력증명서 발급 완료를 실패하였습니다. 다시 시도해주세요. ${error}`);
      setIsConfirmVisible(false);
    }
  };

  useEffect(() => {
    if (isRadio1Check || isRadio2Check || isRadio3Check || isRadio4Check) {
      setIsRadioCheckDone(true);
    } else {
      setIsRadioCheckDone(false);
    }
  }, [isRadio1Check, isRadio2Check, isRadio3Check, isRadio4Check]);

  const onClickResendConfirm = async () => {
    setIsResendVisible(false);
    setIsLoading(true);
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/email/career";
    try {
      const response = await axios.put(endPoint, {
        userName: name,
        companyName: firmNm,
        compEmail: newCompEmail,
        memberEmail: email,
        userId: uid,
        companyNumber: telNumber,
        employmentPeriod: loseDate.length > 0 ? `${acqDate} - ${loseDate}` : `${acqDate} - 현재`,
        position: position,
        department: department,
        job: jobList,
        jobPosition: jobPosition,
        ctfId: id,
      });
      if (response.data.status === "메일 전송 성공") {
        await addToResendDate();
        setIsLoading(false);
        alert("증명서 발급 요청 메일이 재전송 되었습니다.");

        const changeConfirmArray = requestListData.map((item) => (item.ID === response.data.data.ID ? { ...item, AuthStatus: "발급 대기" } : { ...item }));
        requestListStore.setRequestData(changeConfirmArray);
      } else {
        setIsLoading(false);
        alert(`증명서 발급 요청 메일 재전송을 실패하였습니다. 다시 시도해주세요. ${response.data.status}`);
      }
    } catch (error) {
      console.error("Error during axios.get : ", error);
      setIsLoading(false);
      alert(`증명서 발급 요청 메일 재전송을 실패하였습니다. 다시 시도해주세요. ${error}`);
    }
  };

  const onClickResendOpen = async () => {
    setIsResendVisible(true);
  };

  const onClickResendClose = async () => {
    setIsResendVisible(false);
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isSealVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <img className="Seal_Image_Modal" src={seal} alt="sealImg" />
              <button className="Issue_Error_Button" onClick={onClickSealClose}>
                <div className="Email_Right_Button_BG" style={{ marginLeft: "0px" }}>
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {isItemDataVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>ID</span> : {id}
                </div>
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>요청자</span> : {name}
                </div>
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>기업명</span> : {firmNm}
                </div>
                {representativeName === null || representativeName === undefined || representativeName === "" ? null : (
                  <div className="Email_Sub_Error_Copy">
                    <span style={{ fontWeight: 700 }}>대표자명</span> : {representativeName}
                  </div>
                )}
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>재직기간</span> : {formattedAcqDate} ~ {formattedLoseDate}
                </div>
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>포지션</span> : {position}
                </div>
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>부서</span> : {department}
                </div>
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>직위</span> : {jobPosition}
                </div>
                {jobList.length > 0 && jobList[0].Job.length > 0 ? (
                  <>
                    <div className="Email_Sub_Error_Copy">
                      <span style={{ fontWeight: 700 }}>직무</span>
                    </div>
                    <div className="Email_Sub_Error_Copy">- {jobList[0].Job}</div>
                  </>
                ) : null}
                {jobList.length > 1 && jobList[1].Job.length > 0 ? (
                  <>
                    <div className="Email_Sub_Error_Copy">- {jobList[1].Job}</div>
                  </>
                ) : null}
                {jobList.length > 2 && jobList[2].Job.length > 0 ? (
                  <>
                    <div className="Email_Sub_Error_Copy">- {jobList[2].Job}</div>
                  </>
                ) : null}
                {jobList.length > 3 && jobList[3].Job.length > 0 ? (
                  <>
                    <div className="Email_Sub_Error_Copy">- {jobList[3].Job}</div>
                  </>
                ) : null}
                {jobList.length > 4 && jobList[4].Job.length > 0 ? (
                  <>
                    <div className="Email_Sub_Error_Copy">- {jobList[4].Job}</div>
                  </>
                ) : null}
                {rejectReason?.length > 0 ? (
                  <div className="Email_Sub_Error_Copy">
                    <span style={{ fontWeight: 700 }}>거절사유</span> :{""}
                    {rejectReason}
                  </div>
                ) : null}
              </div>
              <button className="Issue_Error_Button" onClick={onClickItemDataClose}>
                <div className="Email_Right_Button_BG" style={{ marginLeft: "0px" }}>
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {isMemoVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Reject_Reason_BG">
                  <textarea
                    className="Reject_Reason_TextArea"
                    name="memo"
                    id="userMemo"
                    placeholder="추가 전달 사항을 입력해주세요."
                    value={memo}
                    onChange={handleMemoChange}
                    required
                  />
                </div>
              </div>
              <div className="Email_RightArea_Buttons_CT" style={{ marginTop: "24px" }}>
                <button className="Issue_Error_Button" style={{ marginRight: "12px", marginTop: "0px" }} onClick={onClickMemoClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                      width: "72px",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Issue_Error_Button" onClick={onClickMemoChange} style={{ marginTop: "0px" }}>
                  <div className="Email_Right_Button_BG" style={{ marginLeft: "0px", width: "72px" }}>
                    저장
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isRejectVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>{name}</span>님의 <span style={{ fontWeight: 700 }}>{firmNm}</span> 경력증명서 발급을 거절하시겠습니까?
                </div>
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickRejectClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickRejectReasonOpen}>
                  <div
                    className="Email_Right_Button_BG"
                    style={{
                      borderColor: "rgb(255, 233, 233)",
                      backgroundColor: "rgb(255, 233, 233)",
                      color: "rgb(216, 44, 44)",
                    }}
                  >
                    확인
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isRejectReasonVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>{name}</span>님의 <span style={{ fontWeight: 700 }}>{firmNm}</span> 경력증명서 발급 거절 사유를 선택해주세요.
                </div>
              </div>
              <div className="Email_RightArea_Buttons_CT2">
                <div className="TermLists_Short_BG">
                  <TermList
                    bodyText={"전체선택"}
                    twoLine
                    isCheckBox={isRadioAllCheck}
                    setIsCheckBox={setIsRadioAllCheck}
                    checkAll
                    checkAllHandler={checkAllHandler}
                    blue
                    shortWidth
                  />
                  <div className="TermLists_Short_Line" />
                  <TermList bodyText={"회사 이메일이 잘못되었습니다."} isCheckBox={isRadio1Check} setIsCheckBox={setIsRadio1Check} black shortWidth />
                  <TermList bodyText={"회사 전화번호가 잘못되었습니다."} isCheckBox={isRadio2Check} setIsCheckBox={setIsRadio2Check} black shortWidth />
                  <TermList bodyText={"증명서 정보가 사실과 다릅니다."} isCheckBox={isRadio3Check} setIsCheckBox={setIsRadio3Check} black shortWidth />
                  <TermList bodyText={"발급처와 연락이 닿지 않습니다."} isCheckBox={isRadio4Check} setIsCheckBox={setIsRadio4Check} black shortWidth />
                </div>
                <div className="Email_Sub_ETC_Copy">기타 전달 사항</div>
                <textarea
                  className="Reject_Reason_TextArea2"
                  name="etcRejectReason"
                  id="etcRejectReason"
                  placeholder="기타 전달 사항을 입력해주세요."
                  value={etcRejectReason}
                  onChange={handleEtcReasonChange}
                />
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickRejectReasonClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={isRadioCheckDone ? onClickRejectConfirm : null}>
                  <div
                    className="Email_Right_Button_BG"
                    style={{
                      borderColor: isRadioCheckDone ? "rgb(55, 105, 252)" : "rgb(170, 170, 170)",
                      backgroundColor: isRadioCheckDone ? "rgb(55, 105, 252)" : "rgb(170, 170, 170)",
                    }}
                  >
                    확인
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isSelfIssueVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>{name}</span>님의 <span style={{ fontWeight: 700 }}>{firmNm}</span> 경력증명서 발급을 직접 진행으로 전환하시겠습니까?
                </div>
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickSelfIssueClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickSelfIssueConfirm}>
                  <div className="Email_Right_Button_BG">확인</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isConfirmVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>{name}</span>님의 <span style={{ fontWeight: 700 }}>{firmNm}</span> 경력증명서 발급을 완료하시겠습니까?
                </div>
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickConfirmClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickIssueConfirm}>
                  <div className="Email_Right_Button_BG">확인</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isResendVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">
                  <span style={{ fontWeight: 700 }}>{name}</span>님의 <span style={{ fontWeight: 700 }}>{firmNm}</span> 증명서 발급 요청 메일을 아래의 주소로{" "}
                  <span style={{ fontWeight: 700, color: "rgb(216,44,44)" }}>재전송</span> 합니다.
                </div>
                <div className="Email_Sub_ETC_Copy">재전송 이메일 주소</div>
                <input
                  className="Resend_Textinput"
                  name="newCompEmail"
                  id="newCompEmail"
                  placeholder="발급 요청 이메일을 재전송 할 이메일 주소를 입력해주세요."
                  value={newCompEmail}
                  onChange={handleNewCompEmailChange}
                  required
                />
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickResendClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickResendConfirm}>
                  <div className="Email_Right_Button_BG">확인</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="AllContent_Between_Detail">
        <div className="RequestList_TopButton_CT">
          <button
            className="RequestListCT_Button_S"
            onClick={seal?.length > 0 ? onClickSealOpen : null}
            style={{
              backgroundColor: seal?.length > 0 ? null : "rgb(190, 190, 190)",
            }}
          >
            <div
              className="RequestList_Button_Copy"
              style={{
                color: seal?.length > 0 ? null : "rgb(227, 227, 227)",
              }}
            >
              직인
            </div>
          </button>
          <button
            className="RequestListCT_Button_L"
            onClick={resist?.length > 0 ? onClickResistOpen : null}
            style={{
              backgroundColor: seal?.length > 0 ? null : "rgb(190, 190, 190)",
            }}
          >
            <div
              className="RequestList_Button_Copy"
              style={{
                color: seal?.length > 0 ? null : "rgb(227, 227, 227)",
              }}
            >
              사업자등록증
            </div>
          </button>
          <button className="RequestListCT_Button_L" onClick={onClickItemDataOpen}>
            <div className="RequestList_Button_Copy">증명서 상세</div>
          </button>
        </div>
        <div className="RequestDetail_Divider" />
        <div className="RequestList_MidContents_CT">
          <div className="RequestList_Item_CT" style={{ marginRight: "24px", width: "96px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">발급 상태</div>
              <div
                className="RequestList_Body_Status"
                style={{
                  backgroundColor:
                    status === "발급 대기"
                      ? "rgb(170,170,170)"
                      : status === "발급 진행"
                      ? "rgb(255,146,18)"
                      : status === "발급 승인"
                      ? "rgb(29,201,26)"
                      : status === "발급 완료"
                      ? "rgb(55,105,252)"
                      : status === "발급 거절"
                      ? "rgb(245,69,69)"
                      : status === "직접 대기"
                      ? "rgb(170,170,170)"
                      : status === "직접 완료"
                      ? "rgb(55,105,252)"
                      : status === "직접 지연"
                      ? "rgb(245,69,69)"
                      : "rgb(70,70,70)",
                  marginTop: "4px",
                }}
              >
                <div className="RequestList_Body_Copy" style={{ color: "white", fontWeight: "600" }}>
                  {status === "발급 대기"
                    ? "대기"
                    : status === "발급 진행"
                    ? "진행"
                    : status === "발급 승인"
                    ? "승인"
                    : status === "발급 거절"
                    ? "거절"
                    : status === "발급 완료"
                    ? "완료"
                    : status}
                </div>
              </div>
            </div>
            <div className="RequestList_VerticalLine" />
          </div>
          <div className="RequestList_Item_CT" style={{ marginRight: "24px", width: "96px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">요청일</div>
              <div className="RequestList_DetailBody_Copy">{AddZeroToDate(date)}</div>
            </div>
            <div className="RequestList_VerticalLine" />
          </div>
          <div className="RequestList_Item_CT" style={{ width: "96px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">유형</div>
              <div className="RequestList_DetailBody_Copy">{type === "재직" ? "재직 증명서" : "경력 증명서"}</div>
            </div>
          </div>
        </div>
        <div className="RequestList_BottomContents_CT">
          <div className="RequestList_Item_CT">
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">요청자</div>
              <div className="RequestList_DetailBody_Copy">{name}</div>
            </div>
          </div>
          <div className="RequestList_Item_CT" style={{ marginTop: "24px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">기업/기관명</div>
              <div className="RequestList_DetailBody_Copy">{companyName}</div>
            </div>
          </div>
          <div className="RequestList_Item_CT" style={{ marginTop: "24px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">기업/기관 이메일</div>
              <div className="RequestList_DetailBody_Copy">{compEmail}</div>
            </div>
          </div>
          <div className="RequestList_Item_CT" style={{ marginTop: "24px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">기업/기관 전화번호</div>
              <div className="RequestList_DetailBody_Copy">{telNumber}</div>
            </div>
          </div>
          <div className="RequestList_Item_CT" style={{ marginTop: "24px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">연락 회차</div>
              <div className="RequestList_ContactNumber_CT">
                <button className="RequestListCT_Button_Icon" onClick={contactTime === 0 ? null : onClickCountDown}>
                  <Remove />
                </button>

                <div
                  className="RequestList_ContactNumber_Copy"
                  style={{
                    marginLeft: "12px",
                    marginRight: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {contactTime}
                </div>

                <button className="RequestListCT_Button_Icon" onClick={contactTime === 3 ? null : onClickCountUp}>
                  <Add />
                </button>
              </div>
            </div>
          </div>
          <div className="RequestList_Item_CT" style={{ marginTop: "24px" }}>
            <div className="RequestList_ItemText_CT">
              <div className="RequestList_DetailLabel_Copy">메모</div>

              <div className="RequestList_Note_CT">
                <div
                  className="RequestList_DetailBody_Copy"
                  style={{
                    color: memo.length > 0 ? "rgb(70,70,70)" : "rgb(170,170,170)",
                    width: "80%",
                  }}
                >
                  {memo.length > 0 ? memo : `추가 전달 사항을 입력해주세요.`}
                </div>
                <button
                  className="RequestListCT_Button_S"
                  onClick={onClickMemoOpen}
                  style={{
                    marginLeft: "24px",
                    backgroundColor: "rgb(231,239,255)",
                    borderColor: "rgb(231,239,255)",
                  }}
                >
                  <div
                    className="RequestList_Button_Copy"
                    style={{
                      color: "rgb(55,105,252)",
                    }}
                  >
                    {memo.length > 0 ? `수정` : `입력`}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="RequestList_CTA_CT">
          {status === "발급 대기" || status === "발급 진행" || status === "발급 승인" ? (
            <button
              className="RequestListCT_CTAButton_BG"
              onClick={onClickRejectOpen}
              style={{
                backgroundColor: "rgb(255,233,233)",
                borderColor: "rgb(255,233,233)",
              }}
            >
              <div className="RequestListCT_IconButton">
                <CancelNone />
              </div>

              <div
                className="RequestList_Button_Copy"
                style={{
                  color: "rgb(216,44,44)",
                }}
              >
                발급 거절
              </div>
            </button>
          ) : null}

          {status === "발급 대기" || status === "발급 진행" ? (
            <button
              className="RequestListCT_CTAButton_BG"
              onClick={onClickSelfIssueOpen}
              style={{
                marginLeft: "24px",
                backgroundColor: "rgb(231,239,255)",
                borderColor: "rgb(231,239,255)",
              }}
            >
              <div className="RequestListCT_IconButton">
                <SelfIssue color={"#3769FC"} />
              </div>
              <div
                className="RequestList_Button_Copy"
                style={{
                  color: "rgb(55,105,252)",
                }}
              >
                직접 발급
              </div>
            </button>
          ) : status === "발급 승인" ? (
            <button
              className="RequestListCT_CTAButton_BG"
              onClick={onClickConfirmOpen}
              style={{
                marginLeft: "24px",
                backgroundColor: "rgb(231,239,255)",
                borderColor: "rgb(231,239,255)",
              }}
            >
              <div className="RequestListCT_IconButton">
                <ConfirmNone color={"#3769FC"} />
              </div>
              <div
                className="RequestList_Button_Copy"
                style={{
                  color: "rgb(55,105,252)",
                }}
              >
                발급 완료
              </div>
            </button>
          ) : null}
        </div>
        {status === "발급 대기" || status === "발급 진행" || status === "발급 승인" ? (
          <div className="RequestList_CTA_CT2">
            <button
              className="RequestListCT_CTAButton_BG"
              onClick={onClickResendOpen}
              style={{
                backgroundColor: "rgb(255,255,255)",
                borderColor: "rgb(170,170,170)",
                borderWidth: "2px",
              }}
            >
              <div className="RequestListCT_IconButton">
                <RequestResend />
              </div>

              <div
                className="RequestList_Button_Copy"
                style={{
                  color: "rgb(130,130,130)",
                }}
              >
                메일 재전송
              </div>
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
});

export default AD03RequestDetailMobile;
