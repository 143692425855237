export const AddDotYYYYMMDD = (dateString) => {
  const dateParts = dateString.match(/\d+/g);

  if (dateParts) {
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);

    return `${year}.${month}.${day}`;
  }

  return "";
};

export const AddZeroToDate = (formattedProceedDate) => {
  const regex = /^(\d{4})\.(\d{1,2})\.(\d{1,2})$/;
  const match = formattedProceedDate && formattedProceedDate.match(regex);

  if (match) {
    const year = match[1];
    const month = match[2].padStart(2, "0"); // 두 자리로 만들기
    const day = match[3].padStart(2, "0"); // 두 자리로 만들기

    return `${year}.${month}.${day}`;
  } else {
    return formattedProceedDate;
  }
};

export const ConvertAcqDateString = (input) => {
  if (input.length === 8) {
    const year = input.slice(0, 4);
    const month = input.slice(4, 6);
    const day = input.slice(6, 8);
    return `${year}.${month}.${day}`;
  } else if (input === "") {
    return "현재";
  } else {
    return input;
  }
};

export const ConvertLoseDateString = (input) => {
  if (input.length === 8) {
    const currentDate = new Date(input.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
    currentDate.setDate(currentDate.getDate() - 1);
    const modifiedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, "");
    const year = modifiedDate.slice(0, 4);
    const month = modifiedDate.slice(4, 6);
    const day = modifiedDate.slice(6, 8);
    return `${year}.${month}.${day}`;
  } else if (input === "") {
    return "현재";
  } else {
    return input;
  }
};
