import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
import IssueComplete from "../Assets/Images/IssueComplete";
import ChevronRightBlue from "../Assets/Icons/ChevronRightBlue";
import * as amplitude from "@amplitude/analytics-browser";

const RQ04RequestProgressComplete = observer(() => {
  const location = useLocation();
  const { name, companyName, type } = location.state || {};
  const screenWidth = window.innerWidth;

  const landingPageUrl = "https://certed.co.kr/";

  const moveToLandingPage = () => {
    amplitude.track("증명서 발급 완료 후 기업 회원 랜딩페이지 진입", { 증명서종류: type, 직장명: companyName });
    window.open(landingPageUrl, "_blank", "noopener noreferrer");
  };

  return (
    <>
      {screenWidth > 1023 ? (
        <div className="AllContent_Between">
          <div className="Email_CT">
            <div className="Issue_Complete_LeftArea_CT">
              <div className="Email_LeftContents_CT">
                <div className="Email_Main_Copy_CT">
                  <div className="Email_Main_1_Copy_CT">
                    <div className="Email_Main_2_Copy">{name}님</div>
                    <div className="Email_Main_1_Copy">의</div>
                  </div>
                  <div className="Email_Main_1_Copy">경력증명서가 발급되었습니다.</div>
                </div>
                <div className="Email_Sub_Copy_CT">
                  <div className="Email_Sub_1_Copy">
                    써티드로 발급한 경력증명서는 자동으로 재발급 할 수 있어
                    <br /> 더욱 편하고 신속한 업무 처리가 가능해집니다.
                  </div>
                </div>
              </div>
              <button className="Email_TextButton_Copy_CT" onClick={moveToLandingPage}>
                <div className="Email_TextButton_Copy">써티드를 더 알아보고 싶다면?</div>
                <ChevronRightBlue size={"36"} />
              </button>
            </div>
            <div className="Issue_Conplete_RightArea_CT ">
              <IssueComplete />
            </div>
          </div>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="AllContent_Between_Detail">
          <div className="Email_CT">
            <div className="Issue_Complete_LeftArea_CT">
              <div className="Email_LeftContents_CT">
                <div className="Email_Main_Copy_CT">
                  <div className="Email_Main_1_Copy_CT">
                    <div className="Email_Main_2_Copy">{name}님</div>
                    <div className="Email_Main_1_Copy">의</div>
                  </div>
                  <div className="Email_Main_1_Copy" style={{ width: "288px" }}>
                    경력증명서가 발급되었습니다.
                  </div>
                </div>
                <div className="Email_Sub_Copy_CT">
                  <div className="Email_Sub_1_Copy">
                    써티드로 발급한 경력증명서는
                    <br />
                    자동으로 재발급 할 수 있어
                    <br /> 더욱 편하고 신속한 업무 처리가 가능해집니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="Issue_Conplete_RightArea_CT ">
              <IssueComplete mobile />
            </div>
          </div>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
});

export default RQ04RequestProgressComplete;
