const IssueComplete = ({ mobile }) => {
  return (
    <svg
      width={mobile ? "336" : "621"}
      height={mobile ? "360" : "666"}
      viewBox="0 0 788 845"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M709.076 669.33C708.935 669.33 708.818 669.307 708.677 669.236C708.232 669.025 708.068 668.485 708.279 668.063C749.907 583.351 731.239 433.246 731.051 431.744C730.981 431.251 731.332 430.829 731.801 430.758C732.317 430.711 732.716 431.04 732.787 431.509C732.974 433.012 751.713 583.633 709.85 668.814C709.686 669.119 709.381 669.307 709.053 669.307L709.076 669.33Z"
          fill="#00AAAA"
        />
        <path
          d="M785.486 356.185C780.842 396.534 738.722 428.433 727.23 428.104C715.34 427.776 674.697 393.577 673.149 352.993C671.507 309.898 700.377 280.182 731.405 281.074C762.432 281.943 790.247 314.827 785.486 356.185Z"
          fill="#66E7C4"
        />
        <path
          d="M682.294 334.827C681.731 334.78 681.379 334.357 681.426 333.865C682.622 319.758 692.144 305.158 692.566 304.548C692.824 304.149 693.387 304.031 693.785 304.29C694.184 304.548 694.301 305.111 694.043 305.51C693.95 305.651 684.358 320.345 683.208 334.005C683.162 334.475 682.786 334.803 682.341 334.803L682.294 334.827Z"
          fill="white"
        />
        <path
          d="M726.902 424.676L721.648 434.511L736.588 434.933L726.902 424.676Z"
          fill="#66E7C4"
        />
        <path
          d="M719.398 648.838L729.811 670.057C730.585 671.63 729.436 673.461 727.701 673.461H687.691C685.392 673.461 684.454 670.527 686.307 669.189L715.904 647.97C717.1 647.125 718.765 647.547 719.398 648.838Z"
          fill="#4E938A"
        />
        <path
          d="M716.234 221.903C719.343 221.903 721.863 219.381 721.863 216.27C721.863 213.159 719.343 210.637 716.234 210.637C713.126 210.637 710.605 213.159 710.605 216.27C710.605 219.381 713.126 221.903 716.234 221.903Z"
          fill="#94BAFD"
        />
        <path
          d="M131.975 650.955C131.858 650.955 131.717 650.932 131.6 650.885C76.5572 625.746 68.3489 484.655 68.0206 478.646C67.9971 478.153 68.3724 477.754 68.8414 477.73C69.3105 477.683 69.7326 478.082 69.756 478.552C69.8264 480.007 78.2224 624.573 132.304 649.265C132.749 649.477 132.937 649.993 132.749 650.439C132.608 650.768 132.28 650.955 131.952 650.955H131.975Z"
          fill="#0336B5"
        />
        <path
          d="M134.951 652.455C134.74 652.455 134.505 652.361 134.341 652.221C133.989 651.892 133.989 651.329 134.318 650.977C176.485 607.037 173.858 463.199 173.811 461.743C173.811 461.251 174.187 460.852 174.679 460.852C175.101 460.922 175.57 461.227 175.57 461.72C175.594 463.175 178.244 607.764 135.584 652.221C135.42 652.408 135.185 652.502 134.951 652.502V652.455Z"
          fill="#0336B5"
        />
        <path
          d="M140.696 670.858C140.368 670.858 140.063 670.671 139.899 670.365C98.0362 585.185 116.775 434.564 116.962 433.061C117.033 432.568 117.431 432.24 117.947 432.31C118.416 432.381 118.768 432.803 118.698 433.296C118.51 434.798 99.842 584.88 141.47 669.614C141.681 670.06 141.493 670.577 141.071 670.788C140.954 670.858 140.813 670.882 140.673 670.882L140.696 670.858Z"
          fill="#0336B5"
        />
        <path
          d="M176.158 357.717C171.514 398.065 129.394 429.964 117.902 429.635C106.012 429.307 65.3686 395.108 63.8207 354.524C62.179 311.429 91.0489 281.714 122.076 282.605C153.104 283.497 180.918 316.359 176.158 357.717Z"
          fill="#94BAFD"
        />
        <path
          d="M72.9657 336.358C72.4028 336.311 72.051 335.889 72.0979 335.396C73.294 321.289 82.8157 306.689 83.2378 306.079C83.4958 305.68 84.0587 305.563 84.4573 305.821C84.856 306.079 84.9733 306.642 84.7153 307.041C84.6215 307.182 75.0295 321.876 73.8803 335.537C73.8334 336.006 73.4582 336.335 73.0126 336.335L72.9657 336.358Z"
          fill="white"
        />
        <path
          d="M117.574 426.207L112.32 436.018L127.236 436.441L117.574 426.207Z"
          fill="#94BAFD"
        />
        <path
          d="M239.291 395.435C230.966 435.197 186.101 463.105 174.703 461.72C162.907 460.288 125.547 422.522 127.728 381.962C130.027 338.89 161.5 311.967 192.316 315.676C223.133 319.385 247.828 354.687 239.291 395.435Z"
          fill="#3769FC"
        />
        <path
          d="M138.563 364.711C138.563 364.711 138.47 364.711 138.399 364.711C137.93 364.617 137.602 364.171 137.696 363.702C140.158 349.759 150.993 336.098 151.462 335.535C151.767 335.159 152.33 335.089 152.705 335.394C153.08 335.699 153.151 336.263 152.846 336.638C152.729 336.779 141.823 350.534 139.431 364.03C139.361 364.453 138.986 364.758 138.563 364.758V364.711Z"
          fill="white"
        />
        <path
          d="M174.68 458.27L168.559 467.565L183.38 469.372L174.68 458.27Z"
          fill="#3769FC"
        />
        <path
          d="M91.2594 407.9C98.2717 439.916 74.444 475.336 65.6025 478.082C56.4326 480.945 16.4698 465.501 4.64976 434.916C-7.89726 402.454 6.38523 372.199 30.3067 364.734C54.2281 357.27 84.0595 375.086 91.2594 407.9Z"
          fill="#49D2F0"
        />
        <path
          d="M64.6886 474.094L63.0234 484.445L76.4147 480.267L64.6886 474.094Z"
          fill="#49D2F0"
        />
        <path
          d="M6.90098 414.332C6.43194 414.332 6.03324 413.956 6.00979 413.487C5.37658 398.817 10.2547 387.597 10.4657 387.128C10.6534 386.682 11.1928 386.494 11.6149 386.682C12.0605 386.87 12.2716 387.386 12.0605 387.832C12.0136 387.949 7.13551 399.169 7.74527 413.417C7.74527 413.91 7.39348 414.309 6.90098 414.332H6.85408H6.90098Z"
          fill="white"
        />
        <path
          d="M130.377 650.37L119.965 671.588C119.191 673.161 120.34 674.992 122.075 674.992H162.085C164.383 674.992 165.321 672.058 163.469 670.72L133.872 649.501C132.676 648.656 131.011 649.079 130.377 650.37Z"
          fill="#7589C6"
        />
        <path
          d="M113.707 61.2395L113.285 59.526C113.402 59.5026 125.55 56.3338 128.247 49.2452C128.833 47.6726 128.927 46.006 128.482 44.2456C124.19 47.0388 118.772 48.0716 113.543 45.1376C109.556 42.8842 107.515 39.8798 107.937 36.8753C108.313 34.2699 110.517 32.1339 113.566 31.4298C117.412 30.5144 123.205 31.9696 127.755 38.6592C128.388 39.5981 128.904 40.5135 129.326 41.4055C130.147 40.7013 130.897 39.9267 131.554 39.1287C135.916 33.8239 137.91 25.6321 132.516 19.3181L133.852 18.168C139.88 25.2096 137.581 34.5516 132.914 40.2319C132.046 41.2646 131.085 42.2505 130.006 43.1189C130.733 45.4662 130.686 47.7195 129.889 49.8555C126.863 57.8126 114.246 61.0752 113.707 61.216V61.2395ZM115.747 32.932C115.114 32.932 114.504 33.0024 113.965 33.1198C111.643 33.6596 109.954 35.2323 109.673 37.1101C109.345 39.3634 111.08 41.7341 114.41 43.5884C119.359 46.3581 124.213 45.1141 127.895 42.5087C127.497 41.5698 126.981 40.6074 126.301 39.6216C122.9 34.6455 118.89 32.9085 115.747 32.9085V32.932Z"
          fill="#8372F1"
        />
        <path
          d="M56.9876 113.16C56.9876 114.592 55.8385 115.742 54.4079 115.742C52.9773 115.742 51.8281 114.592 51.8281 113.16C51.8281 111.728 52.9773 110.578 54.4079 110.578C55.8385 110.578 56.9876 111.728 56.9876 113.16Z"
          fill="#8372F1"
        />
        <path
          d="M95.0055 139.824C96.4303 139.824 97.5853 138.668 97.5853 137.242C97.5853 135.816 96.4303 134.66 95.0055 134.66C93.5808 134.66 92.4258 135.816 92.4258 137.242C92.4258 138.668 93.5808 139.824 95.0055 139.824Z"
          fill="#3769FC"
        />
        <path
          d="M281.591 186.39C283.016 186.39 284.171 185.234 284.171 183.809C284.171 182.383 283.016 181.227 281.591 181.227C280.167 181.227 279.012 182.383 279.012 183.809C279.012 185.234 280.167 186.39 281.591 186.39Z"
          fill="#3769FC"
        />
        <path
          d="M553.007 490.895C553.007 494.017 550.474 496.528 547.379 496.528C544.283 496.528 541.75 493.993 541.75 490.895C541.75 487.797 544.283 485.262 547.379 485.262C550.474 485.262 553.007 487.797 553.007 490.895Z"
          fill="#8372F1"
        />
        <path
          d="M372.425 33.188C372.425 36.3098 369.892 38.8214 366.797 38.8214C363.701 38.8214 361.168 36.2864 361.168 33.188C361.168 30.0897 363.701 27.5547 366.797 27.5547C369.892 27.5547 372.425 30.0897 372.425 33.188Z"
          fill="#94BAFD"
        />
        <path
          d="M334.902 566.008C334.902 569.13 332.369 571.642 329.273 571.642C326.177 571.642 323.645 569.107 323.645 566.008C323.645 562.91 326.177 560.375 329.273 560.375C332.369 560.375 334.902 562.91 334.902 566.008Z"
          fill="#3769FC"
        />
        <path
          d="M692.526 104.848C693.534 107.806 691.986 110.998 689.031 112.031C686.076 113.04 682.887 111.491 681.855 108.534C680.846 105.576 682.394 102.384 685.349 101.351C688.304 100.342 691.494 101.891 692.526 104.848Z"
          fill="#49D2F0"
        />
        <path
          d="M376.834 392.833C377.843 395.79 376.295 398.983 373.34 400.015C370.385 401.025 367.195 399.475 366.163 396.518C365.155 393.56 366.703 390.368 369.658 389.335C372.613 388.326 375.802 389.875 376.834 392.833Z"
          fill="#49D2F0"
        />
        <path
          d="M420.476 214.653C423.585 214.653 426.105 212.131 426.105 209.02C426.105 205.909 423.585 203.387 420.476 203.387C417.368 203.387 414.848 205.909 414.848 209.02C414.848 212.131 417.368 214.653 420.476 214.653Z"
          fill="#94BAFD"
        />
        <path
          d="M123.336 222.14C117.496 222.14 112.149 218.689 108.936 214.441C108.115 213.361 107.388 212.187 106.802 210.943C104.339 211.084 102.158 210.544 100.282 209.277C93.2462 204.512 92.9883 191.461 92.9883 190.898H94.7472C94.7472 190.898 95.0052 203.55 101.29 207.821C102.674 208.76 104.339 209.206 106.098 209.206C104.363 204.395 104.621 198.855 108.678 194.466C111.774 191.086 115.198 189.795 118.012 190.921C120.451 191.884 122.022 194.536 121.999 197.658C121.999 201.625 119.255 206.906 111.68 209.817C110.624 210.216 109.616 210.521 108.631 210.709C109.124 211.671 109.71 212.586 110.343 213.408C114.494 218.877 121.999 222.703 129.386 218.924L130.184 220.496C127.909 221.67 125.587 222.186 123.336 222.186V222.14ZM115.901 192.283C114.072 192.283 111.985 193.456 109.944 195.663C106.122 199.817 106.215 204.841 107.881 209.042C108.889 208.878 109.944 208.573 111.047 208.15C117.801 205.568 120.216 201.015 120.24 197.635C120.24 195.24 119.114 193.245 117.355 192.541C116.91 192.353 116.417 192.283 115.901 192.283Z"
          fill="#94BAFD"
        />
        <path
          d="M328.642 97.9619C326.206 103.274 320.842 106.698 315.644 107.849C314.321 108.145 312.952 108.317 311.578 108.331C310.679 110.63 309.279 112.389 307.345 113.567C300.084 117.979 288.125 112.77 287.614 112.535L288.347 110.935C288.347 110.935 299.944 115.978 306.444 112.042C307.874 111.175 308.974 109.847 309.707 108.247C304.614 107.818 299.691 105.273 297.398 99.7517C295.62 95.5259 295.876 91.8728 298.073 89.7829C299.964 87.9657 303.028 87.6427 305.853 88.9662C309.455 90.6208 313.107 95.3197 312.59 103.424C312.512 104.551 312.368 105.595 312.128 106.57C313.207 106.523 314.283 106.371 315.293 106.138C321.991 104.643 328.596 99.4129 328.246 91.1167L330.006 91.0474C330.123 93.6062 329.624 95.9335 328.685 97.9815L328.642 97.9619ZM298.429 92.2707C297.666 93.9346 297.861 96.3228 299.013 99.099C301.191 104.309 305.792 106.319 310.302 106.557C310.573 105.571 310.736 104.484 310.812 103.305C311.285 96.0842 308.158 91.9876 305.098 90.5564C302.924 89.5577 300.643 89.7495 299.27 91.0557C298.913 91.3827 298.644 91.8013 298.429 92.2707Z"
          fill="#49D2F0"
        />
        <path
          d="M421.087 116.166C416.772 116.166 413.067 115.344 412.762 115.274L413.16 113.56C413.301 113.584 426.669 116.565 431.5 111.166C429.929 110.344 428.428 109.147 427.044 107.645C421.134 101.143 420.196 94.4772 421.838 91.0502C422.588 89.5011 423.878 88.6561 425.309 88.7265C428.334 88.8908 431.406 92.9045 433.752 99.7115C435.253 104.101 435.37 107.692 434.103 110.391C437.176 111.401 440.436 110.931 443.602 108.983C444.422 108.467 445.243 107.856 445.994 107.152C445.876 107.011 445.759 106.87 445.642 106.73C439.685 99.1716 440.037 91.7074 442.171 87.6233C443.437 85.1822 445.407 83.7973 447.401 83.8912C448.691 83.9616 451.106 84.7362 452.818 89.1725C455.187 95.2752 452.701 102.458 448.409 107.199C450.661 109.452 453.076 110.509 455.633 110.368C463.067 109.945 469.516 99.6645 469.587 99.5472L471.088 100.463C470.806 100.909 464.076 111.635 455.75 112.105C452.701 112.293 449.816 111.049 447.19 108.42C446.346 109.194 445.454 109.875 444.54 110.438C440.811 112.762 436.847 113.208 433.189 111.87C433.071 112.011 432.954 112.152 432.837 112.293C430.116 115.344 425.332 116.119 421.111 116.119L421.087 116.166ZM425.121 90.4869C424.23 90.4869 423.714 91.1911 423.433 91.8013C422.26 94.2424 422.635 100.157 428.358 106.471C429.647 107.88 431.031 108.983 432.485 109.711C433.587 107.41 433.47 104.265 432.11 100.275C429.671 93.2097 426.95 90.5808 425.215 90.4869C425.191 90.4869 425.145 90.4869 425.121 90.4869ZM447.19 85.6281C445.923 85.6281 444.633 86.6609 443.742 88.4213C441.702 92.3412 441.936 99.1951 447.049 105.626C447.119 105.72 447.19 105.791 447.26 105.885C451.106 101.613 453.311 95.2283 451.2 89.8062C450.192 87.2008 448.808 85.722 447.331 85.6281C447.284 85.6281 447.237 85.6281 447.213 85.6281H447.19Z"
          fill="#3769FC"
        />
        <path
          d="M298.488 432.532C295.724 429.215 293.981 425.841 293.84 425.562L295.41 424.771C295.482 424.894 301.758 437.077 308.994 437.332C308.618 435.599 308.575 433.678 308.841 431.652C310.044 422.946 314.558 417.955 318.239 417.021C319.909 416.606 321.383 417.056 322.245 418.2C324.057 420.631 322.946 425.563 319.225 431.726C316.819 435.691 314.138 438.081 311.256 438.837C312.45 441.845 314.898 444.05 318.421 445.235C319.343 445.535 320.337 445.775 321.358 445.901C321.391 445.72 321.424 445.54 321.457 445.36C323.44 435.94 329.392 431.429 333.893 430.453C336.577 429.863 338.902 430.49 340.107 432.082C340.879 433.119 341.832 435.472 339.525 439.629C336.359 445.359 329.256 448.049 322.869 447.787C322.582 450.961 323.319 453.494 325.065 455.369C330.151 460.812 342.17 459.183 342.305 459.162L342.564 460.902C342.042 460.972 329.5 462.669 323.807 456.571C321.71 454.348 320.817 451.335 321.151 447.632C320.016 447.479 318.923 447.23 317.905 446.888C313.734 445.511 310.853 442.75 309.536 439.081C309.352 439.081 309.169 439.081 308.986 439.082C304.902 438.945 301.243 435.764 298.539 432.52L298.488 432.532ZM320.775 419.184C320.204 418.499 319.333 418.553 318.684 418.728C316.06 419.39 311.762 423.468 310.583 431.91C310.329 433.804 310.369 435.574 310.742 437.157C313.213 436.531 315.551 434.426 317.741 430.825C321.6 424.425 321.874 420.65 320.835 419.256C320.82 419.238 320.79 419.202 320.775 419.184ZM338.639 433.033C337.827 432.059 336.209 431.73 334.287 432.172C329.973 433.115 324.864 437.686 323.204 445.735C323.177 445.849 323.168 445.948 323.142 446.062C328.883 446.282 335.194 443.887 338.002 438.791C339.355 436.347 339.603 434.337 338.729 433.141C338.699 433.105 338.669 433.069 338.654 433.051L338.639 433.033Z"
          fill="#66E7C4"
        />
        <path
          d="M680.505 497.282L680.458 495.522C680.576 495.522 692.724 495.193 696.852 489.02C698.282 486.907 698.587 484.279 697.79 481.204C691.528 482.495 684.093 480.992 680.036 475.829C677.105 472.073 677.644 469.608 678.254 468.458C679.192 466.674 681.42 465.689 684.117 465.853C688.714 466.111 695.327 469.608 698.798 478.575C698.868 478.739 698.939 478.927 698.986 479.091C699.971 478.786 700.909 478.41 701.777 477.965C705.083 476.228 707.124 473.646 707.827 470.5C704.849 470.219 701.823 468.294 698.822 464.75C694.155 459.281 692.278 454.586 693.685 451.887C694.366 450.572 695.726 449.915 697.461 450.079C701.261 450.432 706.514 454.61 709.094 463.013C709.703 464.961 709.938 466.862 709.868 468.646C716.95 467.214 721.219 454.187 721.265 454.046L722.931 454.586C722.743 455.173 718.31 468.74 710.196 470.359C710.008 470.406 709.821 470.43 709.633 470.453C708.906 474.279 706.514 477.472 702.621 479.514C701.659 480.007 700.627 480.429 699.525 480.758C700.44 484.325 700.041 487.424 698.329 490.006C693.685 496.954 681.045 497.282 680.505 497.282ZM683.578 467.59C681.772 467.59 680.388 468.2 679.825 469.28C679.145 470.594 679.708 472.542 681.443 474.749C685.032 479.326 691.669 480.664 697.297 479.537C697.25 479.443 697.227 479.326 697.18 479.232C694.225 471.557 688.456 467.871 684.047 467.637C683.882 467.637 683.742 467.637 683.578 467.637V467.59ZM696.969 451.816C696.336 451.816 695.609 452.004 695.257 452.708C694.459 454.258 695.327 457.919 700.182 463.623C702.902 466.815 705.552 468.552 708.109 468.763C708.202 467.144 707.991 465.383 707.429 463.553C704.919 455.408 700.018 452.098 697.321 451.84C697.227 451.84 697.11 451.84 696.992 451.84L696.969 451.816Z"
          fill="#94BAFD"
        />
        <path
          d="M445.55 307.32C445.55 308.752 444.401 309.902 442.97 309.902C441.54 309.902 440.391 308.752 440.391 307.32C440.391 305.888 441.54 304.738 442.97 304.738C444.401 304.738 445.55 305.888 445.55 307.32Z"
          fill="#3769FC"
        />
        <path
          d="M628.174 57.4334C629.598 57.4334 630.753 56.2774 630.753 54.8515C630.753 53.4255 629.598 52.2695 628.174 52.2695C626.749 52.2695 625.594 53.4255 625.594 54.8515C625.594 56.2774 626.749 57.4334 628.174 57.4334Z"
          fill="#3769FC"
        />
        <path
          d="M307.463 485.734C308.887 485.734 310.042 484.578 310.042 483.152C310.042 481.726 308.887 480.57 307.463 480.57C306.038 480.57 304.883 481.726 304.883 483.152C304.883 484.578 306.038 485.734 307.463 485.734Z"
          fill="#8372F1"
        />
        <path
          d="M631.22 188.972C632.645 188.972 633.8 187.817 633.8 186.391C633.8 184.965 632.645 183.809 631.22 183.809C629.796 183.809 628.641 184.965 628.641 186.391C628.641 187.817 629.796 188.972 631.22 188.972Z"
          fill="#94BAFD"
        />
        <path
          d="M556.056 598.398C557.481 598.398 558.636 597.242 558.636 595.816C558.636 594.39 557.481 593.234 556.056 593.234C554.632 593.234 553.477 594.39 553.477 595.816C553.477 597.242 554.632 598.398 556.056 598.398Z"
          fill="#66E7C4"
        />
        <path
          d="M326.224 370.719C327.649 370.719 328.804 369.563 328.804 368.137C328.804 366.711 327.649 365.555 326.224 365.555C324.8 365.555 323.645 366.711 323.645 368.137C323.645 369.563 324.8 370.719 326.224 370.719Z"
          fill="#1212FF"
        />
        <path
          d="M100.14 315.348C100.14 316.779 98.9908 317.93 97.5602 317.93C96.1296 317.93 94.9805 316.779 94.9805 315.348C94.9805 313.916 96.1296 312.766 97.5602 312.766C98.9908 312.766 100.14 313.916 100.14 315.348Z"
          fill="white"
        />
        <path
          d="M751.181 137.265C752.606 137.265 753.761 136.109 753.761 134.684C753.761 133.258 752.606 132.102 751.181 132.102C749.757 132.102 748.602 133.258 748.602 134.684C748.602 136.109 749.757 137.265 751.181 137.265Z"
          fill="#66E7C4"
        />
        <path
          d="M174.39 85.5323C177.499 85.5323 180.019 83.0102 180.019 79.899C180.019 76.7878 177.499 74.2656 174.39 74.2656C171.282 74.2656 168.762 76.7878 168.762 79.899C168.762 83.0102 171.282 85.5323 174.39 85.5323Z"
          fill="#49D2F0"
        />
        <path
          d="M40.8083 292.321C43.9168 292.321 46.4368 289.799 46.4368 286.688C46.4368 283.577 43.9168 281.055 40.8083 281.055C37.6997 281.055 35.1797 283.577 35.1797 286.688C35.1797 289.799 37.6997 292.321 40.8083 292.321Z"
          fill="#66E7C4"
        />
        <path
          d="M248.222 23.001C251.331 23.001 253.851 20.4789 253.851 17.3677C253.851 14.2565 251.331 11.7344 248.222 11.7344C245.114 11.7344 242.594 14.2565 242.594 17.3677C242.594 20.4789 245.114 23.001 248.222 23.001Z"
          fill="#8372F1"
        />
        <path
          d="M536.589 82.481C536.589 85.6028 534.056 88.1143 530.961 88.1143C527.865 88.1143 525.332 85.5793 525.332 82.481C525.332 79.3827 527.865 76.8477 530.961 76.8477C534.056 76.8477 536.589 79.3827 536.589 82.481Z"
          fill="#3769FC"
        />
        <path
          d="M457.556 32.7186C458.981 32.7186 460.136 31.5626 460.136 30.1366C460.136 28.7107 458.981 27.5547 457.556 27.5547C456.132 27.5547 454.977 28.7107 454.977 30.1366C454.977 31.5626 456.132 32.7186 457.556 32.7186Z"
          fill="#3769FC"
        />
        <path
          d="M701.463 32.7186C702.887 32.7186 704.042 31.5626 704.042 30.1366C704.042 28.7107 702.887 27.5547 701.463 27.5547C700.038 27.5547 698.883 28.7107 698.883 30.1366C698.883 31.5626 700.038 32.7186 701.463 32.7186Z"
          fill="#3769FC"
        />
        <path
          d="M41.7454 30.8404C41.7454 33.9622 39.2126 36.4737 36.1169 36.4737C33.0211 36.4737 30.4883 33.9387 30.4883 30.8404C30.4883 27.742 33.0211 25.207 36.1169 25.207C39.2126 25.207 41.7454 27.742 41.7454 30.8404Z"
          fill="#3769FC"
        />
      </g>
      <path
        d="M136.025 516.717C137.1 516.717 137.971 515.844 137.971 514.769C137.971 513.693 137.1 512.82 136.025 512.82C134.95 512.82 134.078 513.693 134.078 514.769C134.078 515.844 134.95 516.717 136.025 516.717Z"
        fill="white"
      />
      <path
        d="M147.236 393.49C148.311 393.49 149.182 392.618 149.182 391.542C149.182 390.466 148.311 389.594 147.236 389.594C146.161 389.594 145.289 390.466 145.289 391.542C145.289 392.618 146.161 393.49 147.236 393.49Z"
        fill="white"
      />
      <path
        d="M279.201 355.556L244.116 360.532L239.449 318.024L263.652 309.551L279.201 355.556Z"
        fill="#507849"
      />
      <path
        d="M249.556 611.191L246.906 644.17L210.836 641.424V612.506L249.556 611.191Z"
        fill="#FFCEBD"
      />
      <path
        d="M116.701 543.592L83.6797 542.114L85.1337 505.943L114.004 504.91L116.701 543.592Z"
        fill="#FFCEBD"
      />
      <path
        d="M261.353 400.438C261.353 400.438 284.618 459.282 284.149 512.447C283.68 565.612 267.592 623.118 267.592 623.118L196.977 624.456C196.977 624.456 208.843 568.029 209.852 555.026C210.86 542.022 213.51 459.752 213.51 459.752L214.284 403.841L261.353 400.438Z"
        fill="#3769FC"
      />
      <path
        d="M172.21 400.436C172.21 400.436 170.005 433.367 169.349 450.291C168.692 467.214 149.25 489.935 133.42 495.123C117.613 500.31 102.204 498.338 102.204 498.338L100.188 559.765C100.188 559.765 153.589 574.083 191.112 550.587C228.636 527.092 226.197 478.105 226.197 478.105L228.988 399.121L172.21 400.436Z"
        fill="#3769FC"
      />
      <path
        d="M199.602 282.442C227.815 279.695 251.244 299.506 255.067 327.626L257.342 344.361C257.342 344.361 264.753 399.897 263.51 402.033C262.29 404.192 236.68 409.708 211.915 408.487C187.149 407.267 171.506 402.971 170.287 402.033C169.067 401.117 172.749 286.948 172.749 286.033C172.749 285.587 184.452 283.92 199.602 282.442Z"
        fill="#8B90FF"
      />
      <path
        d="M216.675 351.988C219.607 352.411 225.189 357.246 225.189 357.246C225.189 357.246 235.648 359.499 236.211 362.692C236.774 365.884 231.685 369.006 231.685 369.006C231.685 369.006 234.194 375.648 233.561 378.089C232.928 380.53 231.216 392.22 225.001 394.684C218.786 397.149 193.27 385.225 193.27 385.225L192.496 359.218C192.496 359.218 213.744 351.589 216.675 352.012V351.988Z"
        fill="#FFCEBD"
      />
      <path
        d="M172.752 286.031C172.752 286.031 111.729 306.898 112.972 355.392C114.215 403.885 196.673 395.905 196.673 395.905V351.096L172.752 286.031Z"
        fill="#8B90FF"
      />
      <path
        d="M231.686 369.664L224.041 369.171C223.666 369.148 223.408 368.819 223.431 368.467C223.431 368.091 223.783 367.81 224.135 367.857L231.733 368.35C232.109 368.373 232.367 368.702 232.343 369.054C232.343 369.406 232.038 369.664 231.686 369.664Z"
        fill="#464646"
      />
      <path
        d="M225.191 357.905L207.954 357.623C207.578 357.623 207.297 357.318 207.297 356.942C207.297 356.567 207.649 356.309 207.977 356.285L225.191 356.567C225.566 356.567 225.848 356.872 225.848 357.248C225.848 357.6 225.543 357.905 225.191 357.905Z"
        fill="#464646"
      />
      <path
        d="M250.682 330.397C247.774 330.96 242.45 336.077 242.45 336.077C242.45 336.077 232.108 338.847 231.733 342.086C231.334 345.301 236.564 348.165 236.564 348.165C236.564 348.165 234.383 354.925 235.157 357.319C235.931 359.713 238.205 371.309 244.561 373.445C250.893 375.581 275.776 362.366 275.776 362.366L275.213 336.359C275.213 336.359 253.59 329.81 250.706 330.397H250.682Z"
        fill="#FFCEBD"
      />
      <path
        d="M236.541 348.821C236.213 348.821 235.931 348.563 235.884 348.234C235.838 347.859 236.095 347.53 236.471 347.507L244.022 346.615C244.398 346.568 244.726 346.826 244.749 347.202C244.796 347.577 244.538 347.906 244.163 347.929L236.611 348.821C236.611 348.821 236.565 348.821 236.541 348.821Z"
        fill="#464646"
      />
      <path
        d="M242.451 336.732C242.099 336.732 241.818 336.473 241.794 336.121C241.771 335.746 242.052 335.441 242.404 335.417L259.595 334.267C259.993 334.244 260.275 334.525 260.298 334.877C260.322 335.253 260.04 335.558 259.689 335.581L242.498 336.732C242.498 336.732 242.474 336.732 242.451 336.732Z"
        fill="#464646"
      />
      <path
        d="M196.673 351.756C196.673 351.756 196.556 351.756 196.509 351.756C196.251 351.686 170.57 344.738 148.9 341.898C148.549 341.851 148.291 341.523 148.338 341.147C148.384 340.795 148.713 340.537 149.088 340.584C170.852 343.447 196.626 350.419 196.884 350.489C197.236 350.583 197.447 350.958 197.353 351.31C197.283 351.616 197.001 351.803 196.72 351.803L196.673 351.756Z"
        fill="white"
      />
      <path
        d="M167.521 345.018C167.521 345.018 167.381 345.018 167.334 344.995C165.716 344.502 151.597 339.948 150.518 335.606C150.378 335.019 150.378 334.127 151.245 333.259C151.949 332.554 152.864 332.202 153.942 332.296C159.313 332.648 167.686 343.516 168.037 343.962C168.225 344.197 168.225 344.502 168.037 344.736C167.92 344.924 167.709 345.018 167.498 345.018H167.521ZM153.661 333.587C153.075 333.587 152.582 333.775 152.184 334.174C151.714 334.643 151.738 334.995 151.808 335.277C152.395 337.695 159.852 341.004 165.598 343C162.596 339.432 157.202 333.798 153.872 333.587C153.802 333.587 153.755 333.587 153.684 333.587H153.661Z"
        fill="white"
      />
      <path
        d="M196.673 396.564H170.946C170.57 396.564 170.289 396.259 170.289 395.907C170.289 395.555 170.594 395.25 170.946 395.25H196.673C197.048 395.25 197.33 395.555 197.33 395.907C197.33 396.259 197.025 396.564 196.673 396.564Z"
        fill="white"
      />
      <path
        d="M211.727 282.301C211.727 282.301 299.626 288.685 312.806 323.917C325.564 358.022 275.752 377.457 275.752 377.457L263.72 331.921L211.727 282.301Z"
        fill="#8B90FF"
      />
      <path
        d="M265.833 332.6C265.716 332.6 265.598 332.577 265.481 332.506C265.176 332.319 265.059 331.896 265.247 331.591C274.956 315.606 288.793 310.09 288.934 310.02C289.285 309.879 289.661 310.067 289.801 310.396C289.942 310.748 289.754 311.123 289.426 311.264C289.285 311.311 275.847 316.686 266.396 332.272C266.279 332.483 266.044 332.6 265.833 332.6Z"
        fill="white"
      />
      <path
        d="M275.987 319.858C275.987 319.858 275.799 319.858 275.706 319.811C275.448 319.693 275.283 319.435 275.307 319.153C275.377 318.121 276.057 308.92 279.13 307.018C279.857 306.572 280.654 306.525 281.475 306.878C282.296 307.23 282.812 307.84 282.952 308.638C283.656 312.159 277.16 318.895 276.433 319.67C276.315 319.811 276.128 319.881 275.964 319.881L275.987 319.858ZM280.443 307.957C280.232 307.957 280.044 308.004 279.857 308.122C278.145 309.178 277.207 314.06 276.831 317.299C279.27 314.529 282.038 310.68 281.686 308.896C281.616 308.521 281.381 308.262 280.982 308.098C280.795 308.028 280.607 307.981 280.443 307.981V307.957Z"
        fill="white"
      />
      <path
        d="M211.729 530.145C211.588 530.145 211.448 530.098 211.307 530.004C211.026 529.77 210.979 529.371 211.19 529.065C245.032 486.158 229.107 423.135 228.943 422.502C228.896 422.314 228.943 422.103 229.037 421.962C229.154 421.797 229.318 421.704 229.506 421.68L250.942 419.615C251.317 419.568 251.622 419.849 251.669 420.201C251.715 420.577 251.434 420.882 251.082 420.929L230.421 422.924C232.133 430.247 244.469 489.045 212.245 529.887C212.104 530.051 211.917 530.145 211.729 530.145Z"
        fill="#385432"
      />
      <path
        d="M178.612 422.619C178.354 422.619 178.096 422.619 177.838 422.619C177.462 422.619 177.181 422.314 177.204 421.939C177.204 421.563 177.533 421.305 177.885 421.305C190.666 421.657 191.792 412.456 191.839 412.057C191.886 411.705 192.167 411.423 192.566 411.47C192.918 411.517 193.199 411.846 193.152 412.198C193.152 412.291 191.886 422.643 178.612 422.643V422.619Z"
        fill="#385432"
      />
      <path
        d="M57.5518 498.058C55.3473 499.044 55.8867 513.62 55.4177 514.841C54.9486 516.062 62.9928 520.427 61.5153 522.657C60.0378 524.887 55.8398 527.399 53.6822 530.685C51.6887 533.76 46.8106 587.089 47.2328 597.534C47.2797 598.402 48.0536 599.059 48.9917 598.989C51.3135 598.825 55.8164 598.355 60.3896 597.064C64.5172 595.891 68.7152 594.036 71.4356 591.079C78.5183 583.427 82.6928 566.363 87.4771 560.471C92.2848 554.579 103.073 548.524 101.525 544.58C99.9537 540.637 91.8392 532.703 94.0906 522.61C96.3186 512.517 99.2971 508.034 97.0457 505.03C95.6385 503.152 81.1215 500.476 70.0989 499.021C63.6495 498.152 58.3727 497.706 57.5518 498.058Z"
        fill="#49D2F0"
      />
      <path
        d="M57.5518 498.058C55.3473 499.044 55.8867 513.621 55.4177 514.841C54.9486 516.062 62.9928 520.427 61.5153 522.657C60.0378 524.887 55.8398 527.399 53.6822 530.685C51.6887 533.76 46.8106 587.089 47.2328 597.534C47.2797 598.402 48.0536 599.059 48.9917 598.989C51.3135 598.825 55.8164 598.355 60.3896 597.064L70.0989 498.997C63.6495 498.129 58.3727 497.683 57.5518 498.035V498.058Z"
        fill="#FCE4A8"
      />
      <path
        d="M166.935 491.393C166.161 491.393 165.387 491.135 164.684 490.618C160.368 487.497 158.727 475.479 158.656 474.962C158.61 474.681 158.774 474.399 159.032 474.282C159.29 474.164 159.595 474.211 159.806 474.422C160.931 475.596 170.781 485.9 169.538 489.82C169.351 490.384 168.882 491.111 167.662 491.346C167.428 491.393 167.193 491.416 166.959 491.416L166.935 491.393ZM160.322 476.911C160.978 480.478 162.596 487.473 165.458 489.539C166.091 489.985 166.724 490.149 167.404 490.008C168.037 489.891 168.202 489.609 168.272 489.398C168.929 487.309 164.215 481.182 160.322 476.911Z"
        fill="#385432"
      />
      <path
        d="M205.538 674.144C206.734 676.256 221.181 674.261 222.447 674.613C223.714 674.965 227.255 666.515 229.624 667.783C231.992 669.027 234.9 672.97 238.395 674.778C241.655 676.468 295.15 676.045 305.492 674.613C306.336 674.496 306.923 673.628 306.758 672.712C306.36 670.435 305.469 665.976 303.71 661.539C302.138 657.549 299.863 653.559 296.674 651.141C288.372 644.827 270.994 642.363 264.661 638.185C258.329 633.983 251.247 623.843 247.471 625.768C243.695 627.716 236.612 636.565 226.364 635.344C216.092 634.124 211.354 631.612 208.587 634.147C206.851 635.743 205.632 650.437 205.257 661.563C205.022 668.088 205.092 673.369 205.538 674.167V674.144Z"
        fill="#49D2F0"
      />
      <path
        d="M205.538 674.148C206.734 676.26 221.181 674.265 222.447 674.617C223.714 674.969 227.255 666.519 229.624 667.787C231.992 669.031 234.9 672.974 238.395 674.781C241.655 676.471 295.15 676.049 305.492 674.617C306.336 674.5 306.923 673.631 306.758 672.716C306.36 670.439 305.469 665.979 303.71 661.543H205.257C205.022 668.068 205.092 673.349 205.538 674.148Z"
        fill="#FCE4A8"
      />
      <path
        d="M202.934 242.398C202.934 242.398 219.257 243.173 220.172 227.517C221.157 210.852 205.749 209.256 199.205 215.992C192.662 222.729 193.882 229.583 193.882 229.583C193.882 229.583 189.332 225.475 184.337 230.522C180.678 234.23 182.578 240.286 182.578 240.286C182.578 240.286 174.158 239.957 174.956 247.304C175.73 254.651 180.983 258.383 180.983 258.383L202.934 242.375V242.398Z"
        fill="#011256"
      />
      <path
        d="M188.02 271.223L189.708 288.569C189.708 288.569 193.32 293.24 203.545 293.075C211.167 292.958 213.653 290.986 213.653 290.986L211.73 282.302L209.854 273.828L191.139 271.598L187.996 271.223H188.02Z"
        fill="#FFCEBD"
      />
      <path
        d="M191.16 271.598C191.254 272.067 191.489 272.607 191.817 273.217C196.554 281.503 207.319 282.324 211.728 282.301L209.852 273.828L191.137 271.598H191.16Z"
        fill="#464646"
      />
      <path
        d="M209.852 233.926C209.852 233.926 221.085 254.793 218.67 267.233C216.254 279.673 205.044 278.664 198.219 276.856C191.395 275.049 187.994 271.247 187.994 271.247C187.994 271.247 181.99 273.852 177.37 268.43C172.75 263.008 174.767 256.201 178.168 254.394C181.568 252.586 185.977 254.793 185.977 254.793L182.178 248.784C182.178 248.784 193.412 215.688 209.828 233.949L209.852 233.926Z"
        fill="#FFCEBD"
      />
      <path
        d="M186.213 264.179C186.213 264.179 185.979 264.155 185.885 264.085L177.536 259.226C177.231 259.039 177.114 258.64 177.301 258.311C177.489 257.982 177.888 257.888 178.216 258.076L186.565 262.935C186.87 263.123 186.987 263.522 186.8 263.85C186.682 264.062 186.448 264.179 186.237 264.179H186.213Z"
        fill="#464646"
      />
      <path
        d="M179.081 264.788C178.799 264.788 178.565 264.624 178.448 264.342C178.33 263.99 178.495 263.615 178.846 263.497L183.865 261.666C184.194 261.549 184.592 261.713 184.709 262.066C184.827 262.418 184.663 262.793 184.311 262.911L179.292 264.741C179.292 264.741 179.151 264.788 179.057 264.788H179.081Z"
        fill="#464646"
      />
      <path
        d="M196.156 244.462C196.038 244.462 195.921 244.438 195.804 244.368C195.499 244.18 195.405 243.758 195.593 243.453C197.633 240.19 200.799 240.448 202.206 241.035C202.535 241.176 202.699 241.575 202.558 241.903C202.417 242.232 202.042 242.396 201.69 242.255C201.573 242.209 198.665 241.058 196.718 244.157C196.601 244.368 196.367 244.462 196.156 244.462Z"
        fill="#464646"
      />
      <path
        d="M212.899 241.997C212.782 241.997 212.641 241.95 212.524 241.879C210.531 240.518 208.655 241.856 208.631 241.879C208.35 242.091 207.928 242.044 207.693 241.739C207.482 241.433 207.552 241.034 207.834 240.8C207.928 240.729 210.484 238.922 213.251 240.8C213.556 241.011 213.626 241.41 213.439 241.715C213.298 241.903 213.111 241.997 212.876 241.997H212.899Z"
        fill="#464646"
      />
      <path
        d="M210.579 261.763C210.579 261.763 210.485 261.763 210.461 261.763C210.11 261.693 209.875 261.341 209.945 260.989C211.212 254.487 207.413 250.215 207.389 250.168C207.155 249.886 207.155 249.487 207.436 249.229C207.718 248.994 208.116 248.994 208.374 249.276C208.538 249.464 212.643 254.064 211.259 261.247C211.188 261.552 210.93 261.787 210.602 261.787L210.579 261.763Z"
        fill="#464646"
      />
      <path
        d="M213.627 250.354C213.369 250.354 213.111 250.19 213.017 249.932L211.539 246.059C211.399 245.707 211.586 245.331 211.915 245.19C212.266 245.073 212.642 245.237 212.782 245.566L214.26 249.439C214.401 249.791 214.213 250.166 213.885 250.307C213.814 250.331 213.721 250.354 213.65 250.354H213.627Z"
        fill="#464646"
      />
      <path
        d="M202.697 251.624C202.439 251.624 202.181 251.459 202.087 251.201L200.61 247.328C200.469 246.976 200.657 246.601 200.985 246.46C201.337 246.342 201.712 246.507 201.853 246.835L203.33 250.708C203.471 251.06 203.283 251.436 202.955 251.577C202.885 251.6 202.791 251.624 202.72 251.624H202.697Z"
        fill="#464646"
      />
      <path
        d="M212.174 238.644C212.174 238.644 209.946 235.334 205.631 235.733C201.292 236.132 199.088 239.418 199.088 239.418C199.088 239.418 201.691 246.531 196.203 250.967C190.692 255.403 186.048 254.769 186.048 254.769L181.639 253.666L177.91 247.728L188.956 233.222L204.106 223.223L212.971 229.818L212.244 238.644H212.174Z"
        fill="#011256"
      />
      <path
        d="M206.99 266.199C205.278 266.199 202.815 265.566 201.361 263.124C201.174 262.819 201.267 262.397 201.596 262.209C201.901 262.021 202.323 262.115 202.51 262.444C204.269 265.425 207.693 264.814 207.834 264.791C208.209 264.721 208.538 264.955 208.608 265.307C208.678 265.659 208.444 266.011 208.092 266.082C207.787 266.152 207.412 266.176 206.99 266.176V266.199Z"
        fill="#464646"
      />
      <path
        d="M218.129 258.499C214.471 258.499 211.492 255.119 211.492 250.988C211.492 246.857 214.471 243.477 218.129 243.477C221.788 243.477 224.766 246.857 224.766 250.988C224.766 255.119 221.788 258.499 218.129 258.499ZM218.129 244.791C215.198 244.791 212.829 247.561 212.829 250.988C212.829 254.415 215.198 257.184 218.129 257.184C221.061 257.184 223.429 254.415 223.429 250.988C223.429 247.561 221.061 244.791 218.129 244.791Z"
        fill="#94BAFD"
      />
      <path
        d="M201.458 260.19C197.307 260.19 193.953 256.81 193.953 252.679C193.953 248.548 197.33 245.168 201.458 245.168C205.586 245.168 208.963 248.548 208.963 252.679C208.963 256.81 205.586 260.19 201.458 260.19ZM201.458 246.482C198.034 246.482 195.266 249.252 195.266 252.679C195.266 256.106 198.034 258.876 201.458 258.876C204.882 258.876 207.649 256.106 207.649 252.679C207.649 249.252 204.882 246.482 201.458 246.482Z"
        fill="#94BAFD"
      />
      <path
        d="M212.175 251.623H207.977C207.602 251.623 207.32 251.318 207.32 250.966C207.32 250.614 207.625 250.309 207.977 250.309H212.175C212.55 250.309 212.832 250.614 212.832 250.966C212.832 251.318 212.527 251.623 212.175 251.623Z"
        fill="#94BAFD"
      />
      <path
        d="M181.594 254.345C181.336 254.345 181.078 254.181 180.984 253.923C180.843 253.571 181.007 253.195 181.359 253.054C186.425 251.012 194.352 251.951 194.68 251.998C195.032 252.045 195.313 252.374 195.266 252.749C195.219 253.101 194.915 253.383 194.516 253.336C194.446 253.336 186.589 252.397 181.852 254.298C181.781 254.322 181.687 254.345 181.594 254.345Z"
        fill="#94BAFD"
      />
      <path
        d="M203.753 293.143C198.875 293.143 193.2 291.899 187.688 287.862C187.383 287.65 187.336 287.228 187.547 286.923C187.759 286.618 188.181 286.571 188.486 286.782C201.924 296.664 216.417 288.965 216.558 288.894C216.886 288.73 217.285 288.824 217.449 289.153C217.613 289.481 217.496 289.88 217.191 290.045C217.097 290.092 211.399 293.143 203.753 293.143Z"
        fill="white"
      />
      <path
        d="M555.869 208.714C555.869 208.714 543.885 190.054 540.672 176.487C537.459 162.92 539.429 157.592 541.376 156.84C543.322 156.089 547.215 164.117 547.215 164.117C547.215 164.117 544.565 151.301 548.834 149.705C553.125 148.132 556.714 159.117 556.714 159.117C556.714 159.117 556.714 149.564 560.607 150.55C564.523 151.536 569.401 172.262 569.401 172.262C569.401 172.262 570.386 161.277 573.412 162.004C576.437 162.708 581.127 177.684 580.119 183.669C579.087 189.655 575.053 197.424 575.053 197.424L555.846 208.691L555.869 208.714Z"
        fill="#FFCEBD"
      />
      <path
        d="M564.593 193.458C564.265 193.458 563.983 193.2 563.936 192.871C562.084 177.919 568.697 172.004 568.979 171.769C569.26 171.535 569.682 171.558 569.917 171.84C570.151 172.121 570.128 172.544 569.846 172.779C569.776 172.826 563.491 178.529 565.25 192.707C565.297 193.059 565.039 193.411 564.663 193.458C564.64 193.458 564.616 193.458 564.593 193.458Z"
        fill="#464646"
      />
      <path
        d="M548.035 179.798C547.8 179.798 547.566 179.657 547.449 179.446C547.284 179.118 547.402 178.719 547.73 178.554L568.157 167.921C568.485 167.757 568.884 167.874 569.048 168.203C569.212 168.532 569.095 168.931 568.767 169.095L548.34 179.728C548.34 179.728 548.129 179.798 548.035 179.798Z"
        fill="#464646"
      />
      <path
        d="M652.749 245.777C652.749 245.777 680.118 250.542 683.894 277.089C687.693 303.636 638.631 312.368 638.631 312.368C638.631 312.368 610.324 318.752 600.216 300.96C590.108 283.145 614.241 268.451 614.241 268.451C614.241 268.451 642.266 240.143 652.773 245.777H652.749Z"
        fill="#011256"
      />
      <path
        d="M544.891 206.016L584.126 184.891C584.126 184.891 613.231 272.301 628.1 288.145C643.555 304.622 605.374 335.23 605.374 335.23C605.374 335.23 551.575 297.745 544.867 206.016H544.891Z"
        fill="#94BAFD"
      />
      <path
        d="M723.905 212.261C723.905 212.261 735.889 193.6 739.102 180.034C742.315 166.467 740.345 161.138 738.398 160.387C736.452 159.636 732.559 167.664 732.559 167.664C732.559 167.664 735.209 154.848 730.941 153.252C726.649 151.679 723.061 162.664 723.061 162.664C723.061 162.664 723.061 153.111 719.167 154.097C715.251 155.083 710.373 175.809 710.373 175.809C710.373 175.809 709.388 164.824 706.362 165.551C703.337 166.255 698.647 181.231 699.655 187.216C700.687 193.201 704.721 200.971 704.721 200.971L723.928 212.237L723.905 212.261Z"
        fill="#FFCEBD"
      />
      <path
        d="M715.179 197.001C714.733 196.954 714.475 196.625 714.522 196.25C716.281 182.072 709.996 176.369 709.926 176.322C709.644 176.087 709.621 175.664 709.855 175.383C710.09 175.101 710.512 175.078 710.793 175.312C711.075 175.547 717.688 181.462 715.836 196.414C715.789 196.743 715.507 197.001 715.179 197.001Z"
        fill="#464646"
      />
      <path
        d="M731.739 183.341C731.739 183.341 731.528 183.318 731.434 183.271L711.007 172.638C710.679 172.474 710.562 172.075 710.726 171.746C710.89 171.417 711.289 171.3 711.617 171.464L732.044 182.097C732.373 182.262 732.49 182.661 732.326 182.989C732.208 183.224 731.974 183.341 731.739 183.341Z"
        fill="#464646"
      />
      <path
        d="M644.731 635.464L644.754 616.006L608.474 614.691L607.184 641.52L625.101 646.144L644.731 635.464Z"
        fill="#FFCEBD"
      />
      <path
        d="M603.828 384.289C603.828 384.289 571.886 470.409 577.397 518.222C583.471 570.87 590.507 612.627 591.75 615.373C592.993 618.119 664.64 617.345 664.64 617.345L629.227 388.209L603.828 384.266V384.289Z"
        fill="#6A82FF"
      />
      <path
        d="M717.503 618.517L710.819 604.363L675.805 615.771L685.233 638.21L704.112 635.84L717.503 618.517Z"
        fill="#FFCEBD"
      />
      <path
        d="M678.432 384.289C678.432 384.289 693.84 411.446 691.753 450.833C689.666 490.196 686.335 503.974 686.335 503.974C686.335 503.974 728.831 600.82 727.987 603.191C727.143 605.562 668.84 628.377 664.665 626.428C660.491 624.457 617.597 512.26 615.509 505.171C613.422 498.082 619.262 388.232 619.262 388.232L678.409 384.289H678.432Z"
        fill="#6A82FF"
      />
      <path
        d="M643.981 671C646.654 670.906 648.789 668.629 648.836 665.836C649 654.757 649.14 629.102 647.077 629.079C644.45 629.032 619.52 636.566 619.52 636.566C619.52 636.566 618.981 621.192 608.052 623.797C599.656 625.792 589.829 635.416 585.702 639.782C584.388 641.167 582.747 642.129 580.917 642.552C573.084 644.359 551.555 650.321 551.086 661.588C550.523 675.366 556.175 673.864 558.802 673.91C561.053 673.934 625.711 671.657 643.981 671.023V671Z"
        fill="#0D3088"
      />
      <path
        d="M730.799 652.128C733.074 650.814 733.848 647.833 732.558 645.321C727.492 635.463 717.361 612.742 715.555 613.681C713.256 614.854 693.486 632.975 693.486 632.975C693.486 632.975 685.817 619.666 677.585 626.989C671.277 632.623 667.29 645.65 665.789 651.4C665.32 653.231 664.335 654.827 662.951 656.048C657.041 661.235 641.234 676.422 646.112 686.585C652.093 699.002 656.267 695.082 658.566 693.909C660.536 692.899 715.32 661.118 730.799 652.128Z"
        fill="#0D3088"
      />
      <path
        d="M655.422 614.316C655.164 614.316 654.906 614.151 654.789 613.87C654.484 613.025 623.574 528.9 617.523 512.751C611.589 496.978 621.815 427.899 622.917 420.6L605.726 413.699C605.374 413.558 605.21 413.182 605.351 412.83C605.492 412.478 605.867 412.337 606.219 412.455L623.878 419.543C624.16 419.661 624.324 419.966 624.277 420.271C624.16 421.022 612.739 496.297 618.742 512.282C624.793 528.431 655.703 612.555 656.032 613.4C656.149 613.752 655.985 614.128 655.633 614.245C655.563 614.269 655.469 614.292 655.398 614.292L655.422 614.316Z"
        fill="#3E59E9"
      />
      <path
        d="M675.805 605.021C675.547 605.021 675.289 604.856 675.195 604.598C675.054 604.246 675.218 603.87 675.57 603.73L721.396 585.539C721.748 585.398 722.123 585.562 722.264 585.914C722.404 586.266 722.24 586.642 721.888 586.783L676.063 604.974C676.063 604.974 675.898 605.021 675.828 605.021H675.805Z"
        fill="#3E59E9"
      />
      <path
        d="M650.591 601.17H605.985C605.61 601.17 605.328 600.865 605.328 600.513C605.328 600.161 605.633 599.855 605.985 599.855H650.591C650.966 599.855 651.248 600.161 651.248 600.513C651.248 600.865 650.943 601.17 650.591 601.17Z"
        fill="#3E59E9"
      />
      <path
        d="M643.394 290.211C643.394 290.211 665.51 261.364 674.07 241.788C682.63 222.212 696.232 189.492 696.232 189.492L739.572 206.51C739.572 206.51 728.057 245.732 712.438 276.457C696.795 307.182 686.476 322.979 686.476 322.979C686.476 322.979 685.89 388.795 684.999 389.992C684.108 391.189 660.022 396.681 632.981 395.156C605.941 393.63 600.031 389.687 598.553 388.795C597.076 387.879 609.764 301.666 609.764 301.666C609.764 301.666 622.146 290.54 643.394 290.258V290.211Z"
        fill="#94BAFD"
      />
      <path
        d="M645.294 271.785L646.678 287.887C646.678 287.887 644.168 292.769 634.811 294.342C627.845 295.516 625.242 294.154 625.242 294.154L625.524 285.915L625.805 277.864L642.48 272.654L645.271 271.785H645.294Z"
        fill="#FFCEBD"
      />
      <path
        d="M645.2 271.012C645.2 271.458 645.083 271.998 644.872 272.608C641.964 280.964 629.557 285.189 625.547 285.917L625.828 277.866L645.223 271.012H645.2Z"
        fill="#464646"
      />
      <path
        d="M619.049 241.46C619.049 241.46 612.342 262.397 616.634 273.335C620.925 284.273 631.01 281.457 636.92 278.663C642.83 275.87 645.292 271.809 645.292 271.809C645.292 271.809 651.226 273.171 654.509 267.444C657.792 261.716 654.814 255.848 651.413 254.769C647.989 253.689 644.354 256.459 644.354 256.459L646.817 250.332C646.817 250.332 630.986 222.048 619.073 241.483L619.049 241.46Z"
        fill="#FFCEBD"
      />
      <path
        d="M619.731 255.472C619.473 255.472 619.215 255.308 619.121 255.073C619.121 255.073 618.816 254.393 618.16 254.134C617.691 253.947 617.128 254.017 616.471 254.322C616.143 254.463 615.744 254.322 615.58 253.994C615.416 253.665 615.58 253.266 615.908 253.102C616.917 252.632 617.831 252.562 618.652 252.89C619.848 253.36 620.317 254.51 620.341 254.557C620.482 254.886 620.341 255.285 619.989 255.425C619.895 255.449 619.825 255.472 619.731 255.472Z"
        fill="#464646"
      />
      <path
        d="M631.759 255.262C631.595 255.262 631.431 255.192 631.314 255.074C629.344 253.22 627.725 254.722 627.538 254.91C627.28 255.168 626.858 255.168 626.6 254.91C626.342 254.652 626.342 254.229 626.6 253.971C627.514 253.056 629.86 251.882 632.228 254.112C632.486 254.37 632.51 254.793 632.252 255.051C632.111 255.192 631.947 255.262 631.759 255.262Z"
        fill="#464646"
      />
      <path
        d="M617.007 249.955C616.726 249.955 616.444 249.768 616.374 249.463C616.28 249.11 616.491 248.735 616.843 248.641L620.689 247.585C621.041 247.491 621.416 247.702 621.51 248.054C621.604 248.406 621.393 248.782 621.041 248.876L617.195 249.932C617.195 249.932 617.077 249.955 617.007 249.955Z"
        fill="#464646"
      />
      <path
        d="M631.807 249.768C631.807 249.768 631.69 249.768 631.619 249.745L626.835 248.407C626.483 248.313 626.272 247.938 626.366 247.586C626.46 247.233 626.835 247.022 627.187 247.116L631.971 248.454C632.323 248.548 632.534 248.923 632.44 249.275C632.346 249.557 632.088 249.768 631.807 249.768Z"
        fill="#464646"
      />
      <path
        d="M622.099 265.8C621.794 265.8 621.513 265.589 621.442 265.284C619.895 258.547 622.334 254.768 622.451 254.604C622.662 254.299 623.061 254.228 623.365 254.416C623.67 254.627 623.764 255.026 623.553 255.331C623.553 255.355 621.325 258.829 622.732 264.979C622.826 265.331 622.592 265.683 622.24 265.777C622.193 265.777 622.146 265.777 622.099 265.777V265.8Z"
        fill="#464646"
      />
      <path
        d="M628.851 271.738C627.046 271.738 625.638 270.306 625.568 270.236C625.404 270.072 625.357 269.86 625.404 269.626C625.451 269.414 625.592 269.227 625.803 269.156L631.548 266.879C631.736 266.809 631.971 266.833 632.158 266.95C632.346 267.067 632.44 267.278 632.463 267.49C632.463 267.607 632.533 270.471 630.165 271.48C629.719 271.668 629.274 271.738 628.851 271.738ZM627.397 269.954C628.007 270.306 628.828 270.588 629.649 270.236C630.47 269.884 630.845 269.133 631.009 268.523L627.397 269.954Z"
        fill="#464646"
      />
      <path
        d="M645.245 264.602C645.011 264.602 644.8 264.484 644.683 264.273C644.495 263.968 644.589 263.545 644.894 263.357L652.492 258.687C652.797 258.499 653.219 258.593 653.407 258.898C653.594 259.203 653.501 259.625 653.196 259.813L645.597 264.484C645.597 264.484 645.363 264.578 645.245 264.578V264.602Z"
        fill="#464646"
      />
      <path
        d="M652.632 265.566C652.492 265.566 652.351 265.519 652.234 265.425L648.247 262.35C647.965 262.115 647.895 261.716 648.129 261.411C648.341 261.129 648.763 261.059 649.068 261.294L653.054 264.368C653.336 264.603 653.406 265.002 653.172 265.307C653.031 265.472 652.843 265.566 652.656 265.566H652.632Z"
        fill="#464646"
      />
      <path
        d="M630.823 241.178C630.823 241.178 626.156 241.06 621.724 244.534C617.291 248.008 616.658 250.966 616.658 250.966C616.658 250.966 612.765 243.854 618.44 236.46C624.116 229.066 637.812 226.038 646.653 232C657.184 239.089 655.964 249.276 655.964 249.276L644.332 256.435C644.332 256.435 628.877 255.402 630.823 241.178Z"
        fill="#011256"
      />
      <path
        d="M629.133 295.046C626.882 295.046 625.358 294.835 625.17 294.812C624.818 294.765 624.56 294.436 624.607 294.061C624.654 293.708 624.982 293.45 625.358 293.497C625.498 293.497 639.335 295.328 646.183 287.465C646.418 287.183 646.84 287.16 647.121 287.394C647.403 287.629 647.426 288.052 647.192 288.333C642.173 294.084 634.011 295.046 629.157 295.046H629.133Z"
        fill="white"
      />
      <path
        d="M545.972 220.522C545.738 220.522 545.503 220.405 545.386 220.17C545.222 219.842 545.339 219.442 545.668 219.278L587.342 197.261C587.671 197.097 588.07 197.214 588.234 197.543C588.398 197.872 588.281 198.271 587.952 198.435L546.277 220.452C546.277 220.452 546.066 220.522 545.972 220.522Z"
        fill="white"
      />
      <path
        d="M736.219 217.822C736.219 217.822 736.054 217.822 735.984 217.775L691.354 201.203C691.002 201.086 690.838 200.687 690.956 200.358C691.073 200.006 691.471 199.842 691.8 199.959L736.43 216.531C736.781 216.648 736.946 217.047 736.828 217.376C736.735 217.634 736.477 217.798 736.195 217.798L736.219 217.822Z"
        fill="white"
      />
      <path
        d="M605.401 335.889C605.072 335.889 604.767 335.631 604.744 335.302C603.079 318.778 611.733 305.985 611.827 305.844C612.038 305.539 612.436 305.469 612.741 305.68C613.046 305.891 613.117 306.29 612.905 306.595C612.812 306.713 604.439 319.153 606.057 335.161C606.104 335.537 605.823 335.842 605.471 335.889C605.471 335.889 605.424 335.889 605.401 335.889Z"
        fill="white"
      />
      <path
        d="M431.172 675.11V675.204L433.048 683.583C433.236 684.405 434.033 684.921 434.83 684.733L526.858 664.711C527.631 664.524 528.171 663.773 528.007 662.975L525.873 651.098L523.504 651.872C523.504 651.872 523.879 643.117 523.598 635.723C523.34 628.306 518.555 609.998 516.492 609.904C514.826 609.81 490.084 614.176 479.601 616.03C476.951 616.523 474.934 618.636 474.488 621.311C473.714 626.147 471.651 633.705 465.975 637.226C457.274 642.648 437.199 652.224 434.033 658.89C431.711 663.843 432.626 669.218 433.259 671.636C433.329 671.917 433.329 672.223 433.259 672.457C433.118 672.903 432.743 673.279 432.251 673.443L431.993 673.537C431.43 673.842 431.125 674.452 431.195 675.11H431.172Z"
        fill="#464646"
      />
      <path
        d="M319.891 666.565V666.683L320.219 675.25C320.266 676.072 320.946 676.729 321.767 676.705L415.881 673.56C416.679 673.513 417.335 672.879 417.335 672.058L417.382 659.993L414.92 660.322C414.92 660.322 416.843 651.778 417.922 644.454C419 637.131 417.593 618.259 415.576 617.767C413.958 617.368 388.841 617.227 378.17 617.156C375.473 617.156 373.104 618.87 372.213 621.428C370.595 626.052 367.194 633.094 360.979 635.558C351.434 639.314 329.975 645.135 325.66 651.121C322.494 655.557 322.424 661.026 322.588 663.514C322.588 663.819 322.541 664.101 322.423 664.312C322.212 664.734 321.767 665.04 321.251 665.087L320.969 665.133C320.36 665.321 319.938 665.885 319.914 666.542L319.891 666.565Z"
        fill="#464646"
      />
      <path
        d="M414.452 401.493C414.452 401.493 367.759 470.783 358.401 499.912C349.044 529.041 358.401 625.113 358.401 625.113H423.786C423.786 625.113 420.644 544.017 423.786 529.839C426.929 515.662 438.069 496.204 438.069 496.204L462.694 627.484L533.848 621.569C533.848 621.569 492.244 405.038 488.327 394.804C484.387 384.57 414.476 401.493 414.476 401.493H414.452Z"
        fill="#3298F5"
      />
      <path
        d="M324.77 276.996C324.77 276.996 345.173 298.732 353.147 293.403C361.121 288.075 363.419 280.095 363.419 280.095C363.419 280.095 367.101 252.843 355.351 254.134C341.022 255.73 324.77 276.973 324.77 276.973V276.996Z"
        fill="#FFCEBD"
      />
      <path
        d="M330.539 257.631C330.539 257.631 323.105 247.468 329.93 246.388C336.754 245.309 354.133 260.049 350.826 266.41C347.519 272.771 330.539 257.631 330.539 257.631Z"
        fill="#FFCEBD"
      />
      <path
        d="M324.77 294.062C324.63 294.062 324.489 294.015 324.372 293.945C324.067 293.733 324.02 293.311 324.231 293.006L376.436 221.181C376.647 220.875 377.069 220.829 377.374 221.04C377.679 221.251 377.726 221.674 377.515 221.979L325.31 293.804C325.169 293.991 324.982 294.085 324.77 294.085V294.062Z"
        fill="#AF4D04"
      />
      <path
        d="M323.879 265.635C323.879 265.635 316.445 255.472 323.269 254.392C330.094 253.312 347.472 268.053 344.166 274.414C340.859 280.775 323.879 265.635 323.879 265.635Z"
        fill="#FFCEBD"
      />
      <path
        d="M319.681 275.212C319.681 275.212 311.66 267.466 317.453 265.494C323.246 263.523 340.624 273.757 338.677 279.789C336.731 285.821 319.657 275.188 319.657 275.188L319.681 275.212Z"
        fill="#FFCEBD"
      />
      <path
        d="M360.813 256.339C362.525 259.414 361.001 263.287 357.624 264.32C351.221 266.268 342.52 268.169 343.623 263.569C344.654 259.344 349.579 256.081 353.473 254.133C356.123 252.795 359.359 253.757 360.79 256.339H360.813Z"
        fill="#FFCEBD"
      />
      <path
        d="M349.087 283.402C348.829 283.402 348.571 283.238 348.454 282.979C346.413 277.51 347.586 270.351 348.078 267.934C344.303 267.84 342.192 266.971 341.629 265.305C340.527 262.159 345.897 257.817 346.507 257.348C346.789 257.113 347.211 257.16 347.445 257.465C347.68 257.747 347.633 258.169 347.328 258.404C345.288 260 342.286 263.192 342.872 264.882C343.271 266.009 345.405 266.643 348.876 266.643C349.11 266.643 349.298 266.737 349.415 266.901C349.533 267.065 349.579 267.276 349.533 267.464C349.533 267.558 347.375 276.407 349.673 282.533C349.791 282.885 349.626 283.261 349.275 283.378C349.204 283.402 349.11 283.425 349.04 283.425L349.087 283.402Z"
        fill="#464646"
      />
      <path
        d="M334.946 282.464C332.155 282.464 329.293 280.868 329.106 280.75C328.778 280.563 328.684 280.164 328.848 279.835C329.035 279.53 329.434 279.413 329.763 279.577C329.833 279.624 335.977 283.051 338.112 279.459C338.44 278.873 338.534 278.309 338.323 277.723C337.736 275.915 334.899 274.319 333.843 273.85L323.477 266.174C323.172 265.963 323.125 265.54 323.337 265.235C323.548 264.93 323.97 264.883 324.275 265.094L334.523 272.699C334.523 272.699 338.721 274.554 339.589 277.3C339.894 278.262 339.777 279.225 339.261 280.117C338.229 281.877 336.587 282.44 334.946 282.44V282.464Z"
        fill="#464646"
      />
      <path
        d="M339.145 275.399C337.034 275.399 334.994 274.578 333.751 273.803C333.446 273.615 333.352 273.193 333.54 272.888C333.728 272.582 334.15 272.489 334.455 272.676C334.689 272.817 340.177 276.197 343.413 271.996C345.243 269.625 340.529 265.682 340.482 265.658L328.779 256.105C328.498 255.87 328.451 255.448 328.685 255.166C328.92 254.884 329.342 254.837 329.623 255.072L341.326 264.625C341.561 264.813 347.142 269.367 344.469 272.817C342.968 274.765 341.021 275.423 339.122 275.423L339.145 275.399Z"
        fill="#464646"
      />
      <path
        d="M332.133 295.468C332.133 295.468 350.637 341.098 381.688 350.698C415.999 361.331 433.283 345.98 433.283 345.98L445.337 295.468C445.337 295.468 411.097 299.012 395.853 291.078C387.715 286.83 371.228 269.812 371.228 269.812L332.133 295.468Z"
        fill="#0336B5"
      />
      <path
        d="M521.487 416.046C522.12 418.933 527.327 424.191 527.327 424.191C527.327 424.191 530.282 434.471 533.518 434.824C536.755 435.152 539.499 429.871 539.499 429.871C539.499 429.871 546.276 431.913 548.668 431.115C551.061 430.317 562.576 427.805 564.616 421.421C566.633 415.036 552.984 390.391 552.984 390.391L526.999 391.423C526.999 391.423 520.854 413.182 521.487 416.069V416.046Z"
        fill="#FFCEBD"
      />
      <path
        d="M539.475 430.526C539.147 430.526 538.866 430.292 538.819 429.963L537.787 422.405C537.74 422.03 537.998 421.701 538.35 421.654C538.725 421.607 539.053 421.865 539.1 422.217L540.132 429.775C540.179 430.151 539.921 430.48 539.569 430.526C539.546 430.526 539.499 430.526 539.475 430.526Z"
        fill="#464646"
      />
      <path
        d="M527.327 424.849C526.975 424.849 526.694 424.591 526.67 424.239L525.193 407.057C525.169 406.681 525.427 406.376 525.803 406.329C526.154 406.282 526.483 406.564 526.53 406.94L528.007 424.121C528.031 424.497 527.773 424.802 527.397 424.849H527.35H527.327Z"
        fill="#464646"
      />
      <path
        d="M472.986 296.008L438.065 300.702L421.039 406.21C421.039 406.21 442.123 410.552 458.633 408.98C475.144 407.407 479.482 405.036 479.482 405.036L472.963 296.008H472.986Z"
        fill="#00D299"
      />
      <path
        d="M445.431 296.008C445.15 296.712 444.915 297.346 444.798 297.933C444.798 297.933 440.271 312.251 434.948 352.294C431.5 378.231 429.718 401.633 428.616 412.266L406.5 409.332C406.5 409.332 414.755 314.105 420.665 306.265C425.121 300.327 438.982 297.205 445.454 296.031L445.431 296.008Z"
        fill="#0336B5"
      />
      <path
        d="M567.688 392.713L517.406 397.947C517.406 397.947 514.334 379.075 508.752 370.391C503.686 362.504 495.853 361.424 495.853 361.424V412.265H462.691L467.382 301.734L466.983 293.472C484.455 292.909 511.355 295.655 533.142 313.658C567.312 341.942 567.688 392.713 567.688 392.713Z"
        fill="#0336B5"
      />
      <path
        d="M469.071 284.367L467.382 301.713C467.382 301.713 463.771 306.384 453.545 306.22C445.923 306.102 443.438 304.131 443.438 304.131L445.361 295.446L447.237 286.973L465.952 284.743L469.094 284.367H469.071Z"
        fill="#FFCEBD"
      />
      <path
        d="M465.927 284.766C465.833 285.235 465.599 285.775 465.27 286.385C460.533 294.671 449.768 295.492 445.359 295.469L447.236 286.995L465.951 284.766H465.927Z"
        fill="#464646"
      />
      <path
        d="M447.213 247.07C447.213 247.07 435.979 267.937 438.395 280.377C440.811 292.818 452.021 291.808 458.846 290.001C465.67 288.194 469.071 284.391 469.071 284.391C469.071 284.391 475.075 286.997 479.695 281.574C484.315 276.152 482.298 269.345 478.897 267.538C475.497 265.731 471.088 267.937 471.088 267.937L474.887 261.928C474.887 261.928 463.653 228.832 447.237 247.094L447.213 247.07Z"
        fill="#FFCEBD"
      />
      <path
        d="M470.852 277.326C470.618 277.326 470.407 277.209 470.29 276.997C470.102 276.669 470.219 276.27 470.524 276.082L478.873 271.223C479.178 271.035 479.6 271.153 479.788 271.458C479.975 271.763 479.858 272.186 479.553 272.373L471.204 277.232C471.204 277.232 470.993 277.326 470.876 277.326H470.852Z"
        fill="#464646"
      />
      <path
        d="M477.983 277.933C477.983 277.933 477.842 277.933 477.748 277.886L472.729 276.055C472.377 275.938 472.213 275.539 472.331 275.21C472.448 274.858 472.846 274.694 473.175 274.811L478.194 276.642C478.545 276.759 478.71 277.158 478.592 277.487C478.499 277.745 478.241 277.933 477.959 277.933H477.983Z"
        fill="#464646"
      />
      <path
        d="M460.91 257.606C460.698 257.606 460.464 257.489 460.347 257.301C458.4 254.203 455.492 255.353 455.375 255.4C455.046 255.541 454.648 255.4 454.507 255.048C454.366 254.719 454.507 254.32 454.859 254.179C456.243 253.593 459.432 253.334 461.472 256.597C461.66 256.902 461.566 257.325 461.261 257.512C461.144 257.583 461.027 257.606 460.91 257.606Z"
        fill="#464646"
      />
      <path
        d="M444.188 255.165C443.977 255.165 443.766 255.071 443.649 254.883C443.438 254.578 443.532 254.155 443.837 253.968C446.604 252.09 449.137 253.897 449.254 253.968C449.559 254.179 449.606 254.601 449.395 254.907C449.184 255.212 448.762 255.259 448.48 255.047C448.41 255 446.581 253.709 444.587 255.047C444.47 255.118 444.353 255.165 444.212 255.165H444.188Z"
        fill="#464646"
      />
      <path
        d="M446.509 274.907C446.204 274.907 445.923 274.696 445.852 274.368C444.468 267.209 448.549 262.608 448.737 262.397C448.995 262.139 449.393 262.115 449.675 262.35C449.956 262.608 449.956 263.007 449.722 263.289C449.675 263.336 445.899 267.608 447.166 274.109C447.236 274.461 447.001 274.814 446.65 274.884C446.603 274.884 446.556 274.884 446.532 274.884L446.509 274.907Z"
        fill="#464646"
      />
      <path
        d="M443.437 263.499C443.437 263.499 443.273 263.499 443.203 263.452C442.851 263.312 442.687 262.936 442.828 262.584L444.305 258.711C444.446 258.359 444.821 258.195 445.173 258.335C445.525 258.476 445.689 258.852 445.548 259.204L444.071 263.077C443.977 263.335 443.719 263.499 443.461 263.499H443.437Z"
        fill="#464646"
      />
      <path
        d="M454.39 264.788C454.39 264.788 454.226 264.788 454.156 264.741C453.804 264.601 453.64 264.225 453.781 263.873L455.258 260C455.399 259.648 455.774 259.484 456.126 259.625C456.478 259.765 456.642 260.141 456.501 260.493L455.024 264.366C454.93 264.624 454.672 264.788 454.414 264.788H454.39Z"
        fill="#464646"
      />
      <path
        d="M444.914 251.785C444.914 251.785 447.142 248.475 451.457 248.874C455.796 249.273 458.001 252.559 458.001 252.559C458.001 252.559 455.397 259.672 460.885 264.108C466.397 268.544 471.04 267.91 471.04 267.91L475.449 266.807L479.178 260.869L468.132 246.363L453.615 238.781L444.75 245.377L444.867 251.761L444.914 251.785Z"
        fill="#011256"
      />
      <path
        d="M450.076 279.366C449.654 279.366 449.279 279.319 448.974 279.272C448.622 279.202 448.387 278.849 448.458 278.497C448.528 278.145 448.856 277.911 449.232 277.981C449.372 278.004 452.796 278.615 454.555 275.634C454.743 275.329 455.165 275.211 455.47 275.399C455.775 275.587 455.892 275.986 455.705 276.314C454.251 278.756 451.788 279.389 450.076 279.389V279.366Z"
        fill="#464646"
      />
      <path
        d="M336.121 203.411C334.573 203.059 333.33 204.725 334.128 206.087L345.408 225.663L357.392 246.436C358.049 247.562 359.644 247.656 360.441 246.6L371.487 231.648L382.533 216.696C383.307 215.64 382.744 214.138 381.478 213.833L358.119 208.458L336.121 203.388V203.411Z"
        fill="#8793FF"
      />
      <path
        d="M442.71 256.949C442.71 256.949 444.281 250.518 447.213 247.067C450.144 243.617 462.035 249.813 465.928 256.949C469.821 264.085 471.064 267.934 471.064 267.934C471.064 267.934 476.739 265.446 478.873 267.535L481.008 269.624C481.008 269.624 485.933 262.442 486.308 260.916L486.707 259.39C486.707 259.39 487.879 260.306 489.849 257.888C491.819 255.47 491.749 240.119 475.449 233.031C459.15 225.942 447.963 237.913 444.14 243.03C440.318 248.147 442.686 256.996 442.686 256.996L442.71 256.949Z"
        fill="#3769FC"
      />
      <path
        d="M452.655 307.109C446.862 307.109 443.251 304.809 443.063 304.692C442.758 304.481 442.664 304.082 442.876 303.776C443.087 303.471 443.485 303.377 443.79 303.589C443.837 303.612 448.739 306.734 456.126 305.513C463.748 304.246 466.891 300.514 466.914 300.49C467.149 300.209 467.571 300.162 467.852 300.396C468.134 300.631 468.181 301.054 467.946 301.335C467.805 301.5 464.499 305.49 456.337 306.828C455.024 307.039 453.781 307.133 452.632 307.133L452.655 307.109Z"
        fill="white"
      />
      <path
        d="M393.13 307.017C392.825 307.017 392.497 306.97 392.169 306.899C390.902 306.618 390.011 305.89 389.636 304.81C388.088 300.539 394.584 291.689 395.335 290.68C395.499 290.469 395.757 290.375 396.015 290.422C396.273 290.492 396.461 290.68 396.507 290.938C396.742 292.159 398.665 302.956 395.382 306.101C394.772 306.688 393.998 306.993 393.154 306.993L393.13 307.017ZM395.452 292.769C393.271 295.938 389.917 301.712 390.855 304.341C391.09 304.998 391.606 305.397 392.45 305.609C393.271 305.796 393.928 305.655 394.444 305.186C396.461 303.238 395.992 296.595 395.452 292.793V292.769Z"
        fill="white"
      />
      <path
        d="M508.751 371.049C508.587 371.049 508.422 370.978 508.282 370.861C508.071 370.673 508.024 370.368 508.141 370.11C508.329 369.734 512.527 360.838 516.678 359.641C517.639 359.36 518.53 359.477 519.328 359.993C520.289 360.604 520.477 361.402 520.43 361.942C520.242 365.369 510.885 370.086 509.032 371.002C508.938 371.049 508.845 371.072 508.751 371.072V371.049ZM517.663 360.815C517.475 360.815 517.264 360.838 517.053 360.909C514.661 361.613 511.87 365.932 510.275 368.866C514.215 366.777 519.023 363.632 519.117 361.871C519.117 361.683 519.093 361.425 518.624 361.12C518.319 360.932 517.991 360.838 517.663 360.838V360.815Z"
        fill="white"
      />
      <path
        d="M412.975 354.55C412.529 354.503 412.271 354.174 412.318 353.798L415.273 327.955C415.32 327.603 415.625 327.345 416 327.369C416.352 327.416 416.633 327.744 416.586 328.096L413.631 353.939C413.584 354.268 413.303 354.526 412.975 354.526V354.55Z"
        fill="white"
      />
      <path
        d="M495.852 362.083C495.477 362.083 495.195 361.777 495.195 361.425V354.454C495.195 354.079 495.5 353.797 495.852 353.797C496.204 353.797 496.509 354.102 496.509 354.454V361.425C496.509 361.801 496.204 362.083 495.852 362.083Z"
        fill="white"
      />
      <path
        d="M438.066 496.859C437.737 496.859 437.456 496.601 437.409 496.272L430.561 439.845L409.9 435.479C409.548 435.409 409.313 435.057 409.384 434.681C409.454 434.329 409.806 434.094 410.181 434.165L431.312 438.625C431.593 438.695 431.804 438.906 431.828 439.188L438.746 496.085C438.793 496.437 438.535 496.789 438.16 496.812C438.136 496.812 438.113 496.812 438.089 496.812L438.066 496.859Z"
        fill="#E7EFFF"
      />
      <path
        d="M490.743 435.481C477.563 433.744 470.574 420.459 470.269 419.895C470.105 419.567 470.222 419.168 470.55 419.003C470.879 418.839 471.277 418.956 471.442 419.285C471.512 419.426 478.43 432.523 490.813 434.166C491.165 434.213 491.423 434.542 491.376 434.918C491.329 435.246 491.048 435.504 490.719 435.504L490.743 435.481Z"
        fill="#E7EFFF"
      />
      <path
        d="M423.786 530.495C421.534 530.448 410.254 530.049 409.011 527.162C408.8 526.693 408.729 525.941 409.527 525.073C413.373 520.801 423.106 528.453 424.208 529.321C424.419 529.509 424.513 529.791 424.419 530.073C424.325 530.331 424.067 530.519 423.786 530.519V530.495ZM412.693 525.096C411.802 525.096 411.051 525.355 410.512 525.941C410.254 526.223 410.16 526.458 410.23 526.622C410.77 527.866 416.234 528.782 421.675 529.087C419.142 527.303 415.343 525.096 412.693 525.096Z"
        fill="#E7EFFF"
      />
      <path
        d="M465.809 645.156C463.792 645.156 447.376 644.992 443.834 639.992C443.06 638.889 442.92 637.645 443.412 636.284C443.905 634.922 444.843 633.984 446.227 633.514C448.361 632.786 451.292 633.279 454.294 634.265C451.785 632.27 449.744 630.111 449.252 628.068C448.97 626.895 449.205 625.815 449.909 624.9C450.636 623.961 451.621 623.491 452.723 623.538C458 623.773 465.669 635.509 466.536 636.871C466.724 637.176 466.654 637.598 466.325 637.786C466.02 637.974 465.598 637.903 465.411 637.575C463.183 634.101 456.546 625.041 452.653 624.853C451.949 624.806 451.41 625.088 450.94 625.698C450.471 626.308 450.331 626.989 450.518 627.763C451.198 630.604 455.865 634.077 460.204 636.73C463.534 638.349 466.091 639.945 466.325 640.086C466.63 640.274 466.724 640.65 466.56 640.978C466.396 641.283 465.997 641.401 465.692 641.26C465.434 641.143 462.737 639.828 459.594 637.927C455.303 635.838 449.791 633.725 446.672 634.805C445.687 635.134 445.03 635.791 444.679 636.777C444.35 637.716 444.421 638.514 444.937 639.265C447.657 643.138 461.048 643.865 465.997 643.865C466.372 643.865 466.654 644.17 466.654 644.523C466.654 644.875 466.372 645.18 465.997 645.18H465.809V645.156Z"
        fill="white"
      />
      <path
        d="M363.557 639.76C362.22 639.76 344.865 639.666 341.371 634.549C340.621 633.445 340.527 632.225 341.043 630.887C341.512 629.69 342.403 628.845 343.693 628.422C346.554 627.437 351.08 628.704 355.114 630.324C351.291 627.437 347.516 624.033 347.07 621.498C346.929 620.724 347.093 620.019 347.539 619.48C348.712 618.024 350.119 617.367 351.69 617.555C357.6 618.212 363.932 630.981 364.19 631.521C364.354 631.849 364.214 632.248 363.885 632.413C363.557 632.577 363.158 632.436 362.994 632.108C361.282 628.587 355.841 619.362 351.549 618.869C350.424 618.728 349.462 619.221 348.571 620.325C348.36 620.583 348.289 620.888 348.36 621.287C349.017 624.972 359.828 632.225 363.932 634.689C364.237 634.877 364.354 635.276 364.167 635.605C363.979 635.91 363.58 636.027 363.252 635.84C363.111 635.769 349.626 627.812 344.092 629.69C343.2 629.995 342.591 630.558 342.262 631.38C341.91 632.295 341.957 633.093 342.45 633.798C345.053 637.624 358.374 638.445 363.463 638.445H363.58C363.956 638.445 364.237 638.75 364.237 639.102C364.237 639.478 363.932 639.76 363.58 639.76H363.534H363.557Z"
        fill="white"
      />
      <path
        d="M486.73 260.025C486.472 260.025 486.214 259.861 486.097 259.603L484.76 255.965C484.643 255.613 484.807 255.237 485.159 255.12C485.511 255.002 485.886 255.167 486.003 255.519L487.34 259.157C487.457 259.509 487.293 259.884 486.941 260.002C486.871 260.025 486.777 260.049 486.707 260.049L486.73 260.025Z"
        fill="white"
      />
      <path
        d="M358.399 244.486C358.258 244.486 358.141 244.439 358.024 244.369C357.719 244.158 357.649 243.735 357.86 243.454L370.829 225.004C371.04 224.699 371.462 224.629 371.743 224.84C372.048 225.051 372.119 225.474 371.908 225.756L358.938 244.205C358.798 244.393 358.61 244.486 358.399 244.486Z"
        fill="#F9BF30"
      />
    </svg>
  );
};

export default IssueComplete;
