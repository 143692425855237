import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
import TermList from "../Components/TermList";
import CertificatePDF from "../Components/CertificatePDF";
import LoadingData from "../Components/LoadingData";
import EmailProfile from "../Components/EmailProfile";
import Close from "../Assets/Icons/Close";
import FontFaceObserver from "fontfaceobserver";

const RQ03RequestProgress = observer(() => {
  const location = useLocation();
  const { id, name, birthday, address, workDate, companyName, telNumber, position, department, jobPosition, jobList, requestDate, requestNumber, email, uid, ctfId, loseDate } =
    location.state || {};

  const [sealText, setSealText] = useState("");
  const [sealUrl, setSealUrl] = useState(""); // Add state to store the seal image URL
  const canvasRef = useRef(null);
  const downloadLinkRef = useRef(null);

  const generateSeal = () => {
    const specialCharRegex = /[^가-힣a-zA-Z0-9\s]/;
    if (sealText.length > 20) {
      alert("21글자 이상은 입력할 수 없습니다.");
      return;
    } else if (sealText.length === 0) {
      alert("직인에 들어갈 기업/기관명을 입력해주세요.");
      return;
    } else if (specialCharRegex.test(sealText)) {
      alert("특수문자 및 기호는 입력할 수 없습니다.");
      return;
    }

    const loadFonts = async () => {
      const kimjungchul = new FontFaceObserver("KimjungchulMyungjo-Bold");
      const hancom = new FontFaceObserver("HancomHoonminjeongeumH");
      await Promise.all([kimjungchul.load(), hancom.load()]);
    };

    loadFonts()
      .then(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the outer circle
        ctx.beginPath();
        ctx.arc(150, 150, 140, 0, 2 * Math.PI);
        ctx.strokeStyle = "rgb(203, 25, 25)";
        ctx.lineWidth = 8;
        ctx.stroke();

        // Draw the inner circle
        ctx.beginPath();
        ctx.arc(150, 150, 80, 0, 2 * Math.PI);
        ctx.strokeStyle = "rgb(203, 25, 25)";
        ctx.lineWidth = 8;
        ctx.stroke();

        // Draw text in the inner circle (multiline text)
        ctx.font = "72px KimjungchulMyungjo-Bold"; // Font size and type for inner text
        ctx.fillStyle = "rgb(203, 25, 25)"; // Color for inner text
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText("대표", 150, 126); // First line
        ctx.fillText("이사", 150, 190); // Second line

        // Determine font size for outer text based on sealText length
        let outerFontSize = sealText.length >= 18 ? 42 : sealText.length >= 14 ? 48 : 54;
        ctx.font = `${outerFontSize}px HancomHoonminjeongeumH`; // Set font size

        // Draw the seal text around the outer circle
        const radius = 109;
        const angleStep = (2 * Math.PI) / (sealText.length + 1); // Adjust angle step to include ●

        ctx.fillStyle = "rgb(203, 25, 25)"; // Color for outer text
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        // Add the ● at the beginning of the text
        const fullText = `•${sealText}`;

        for (let i = 0; i < fullText.length; i++) {
          const angle = i * angleStep - Math.PI / 2;
          const x = 150 + radius * Math.cos(angle);
          const y = 150 + radius * Math.sin(angle);

          // Draw small circle at the position where the text starts
          if (fullText[i] === "•") {
            ctx.beginPath();
            ctx.arc(x, y, 8, 0, 2 * Math.PI); // 8px radius for the dot
            ctx.fillStyle = "rgb(203, 25, 25)"; // Same color as the text
            ctx.fill();
          } else {
            ctx.save();
            ctx.translate(x, y);
            ctx.rotate(angle + Math.PI / 2);
            ctx.fillText(fullText[i], 0, 0);
            ctx.restore();
          }
        }

        // Convert canvas to data URL and set sealUrl state
        const dataURL = canvas.toDataURL("image/png");
        setSealUrl(dataURL); // Update state with the data URL
        amplitude.track("직인 이미지 생성");
        const downloadLink = downloadLinkRef.current;
        if (downloadLink) {
          downloadLink.href = dataURL;
          downloadLink.style.display = "inline";
        }
      })
      .catch((err) => {
        console.error("Font loading failed:", err);
        alert("서체 로딩에 실패했습니다. 다시 시도해주세요.");
      });
  };

  const [isRadioAllCheck, setIsRadioAllCheck] = useState(false);
  const [isRadio1Check, setIsRadio1Check] = useState(false);
  const [isRadio2Check, setIsRadio2Check] = useState(false);
  const [isRadio3Check, setIsRadio3Check] = useState(false);
  const [isRadio4Check, setIsRadio4Check] = useState(false);
  const [termsDone, setTermsDone] = useState(false);

  const [companyHeadName, setCompanyHeadName] = useState("");

  const [sealImageSrc, setSealImageSrc] = useState(null);
  const [sealImageUrl, setSealImageUrl] = useState(null);
  const [sealImageSrcDone, setSealImageSrcDone] = useState(false);

  const [resistImageSrc, setResistImageSrc] = useState(null);
  const [resistImageUrl, setResistImageUrl] = useState(null);
  const [resistImageSrcDone, setResistImageSrcDone] = useState(false);

  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isMakeSeal, setIsMakeSeal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const checkAllHandler = () => {
    if (!isRadioAllCheck) {
      setIsRadio1Check(true);
      setIsRadio2Check(true);
      setIsRadio3Check(true);
      setIsRadio4Check(true);
    } else {
      setIsRadio1Check(false);
      setIsRadio2Check(false);
      setIsRadio3Check(false);
      setIsRadio4Check(false);
    }
  };

  const onClickErrorClose = async () => {
    setIsErrorVisible(false);
  };

  const onClickMakeSeal = async () => {
    setIsMakeSeal(true);
  };

  const onCloseMakeSeal = async () => {
    setIsMakeSeal(false);
  };

  const sealImageInput = useRef();
  const resistImageInput = useRef();

  const onClickSealImageUpload = async () => {
    amplitude.track("직인 등록 버튼 클릭");
    sealImageInput.current.value = null;
    sealImageInput.current.click();
  };

  const onClickDeleteSealImage = async () => {
    setSealImageSrc(null);
    setSealImageUrl(null);
    setSealImageSrcDone(false);
  };

  const onClickResistImageUpload = async () => {
    amplitude.track("사업자등록증 등록 버튼 클릭");
    resistImageInput.current.click();
  };

  const onSealUpload = (e) => {
    const file = e.target.files[0];
    const maxSize = 10485760;
    if (file.size > maxSize) {
      alert("파일용량은 10MB를 초과할 수 없습니다.");
      e.target.value = null;
    } else {
      amplitude.track("직인 등록");
      setSealImageSrc(e.target.files[0]);
    }
  };

  const onResistUpload = (e) => {
    const file = e.target.files[0];
    const maxSize = 10485760;
    if (file.size > maxSize) {
      alert("파일용량은 10MB를 초과할 수 없습니다.");
      e.target.value = null;
    } else {
      amplitude.track("사업자등록증 등록");
      setResistImageSrc(e.target.files[0]);
    }
  };

  const handleIssueComplete = async (e) => {
    amplitude.track("기업/기관 최종 발급 버튼 클릭", {
      증명서종류: workDate.includes("현재") ? "재직증명서" : "경력증명서",
      직장명: companyName,
    });
    e.preventDefault();
    if (resistImageSrcDone && sealImageSrcDone && termsDone && companyHeadName.length > 0) {
      const data = {
        companyName: companyName,
        companyNumber: telNumber,
        employmentPeriod: workDate,
        position: position,
        department: department,
        job: jobList,
        jobPosition: jobPosition,
        birthday: birthday,
        address: address,
        name: name,
        representativeName: companyHeadName,
      };

      const formData = new FormData();

      formData.append("email", JSON.stringify(email));
      formData.append("userId", JSON.stringify(uid));
      formData.append("ctfId", JSON.stringify(ctfId));
      formData.append("data", JSON.stringify(data));
      formData.append("seal", sealImageSrc);
      formData.append("businessRegistration", resistImageSrc);

      try {
        setIsLoading(true);
        const resistEndPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/accept";
        const response = await axios.post(resistEndPoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [
            function () {
              return formData;
            },
          ],
        });
        if (response.data.message === "증명서 발급 승인") {
          amplitude.track("기업/기관 증명서 발급", {
            유저: name,
            직장명: companyName,
            증명서종류: workDate.includes("현재") ? "재직증명서" : "경력증명서",
            약관동의: termsDone,
            대표자명: companyHeadName,
            직인: sealImageSrcDone,
            사업자등록증: resistImageSrcDone,
          });
          setIsLoading(false);
          navigate(`/email/${id}/issue/complete`, { state: { name: name, companyName: companyName, type: workDate.includes("현재") ? "재직증명서" : "경력증명서" } });
        } else {
          alert(`증명서 발급을 실패하였습니다. 다시 시도해주세요.`);
          setIsLoading(false);
          console.log("issue axios error : ", response.data.message);
        }
      } catch (error) {
        const url = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/dev/web/apple/dev";
        await axios.post(url, {
          type: "Issue Certificate Error on B2B Web - v1/web/accept",
          name: name,
          companyName: companyName,
          workDate: workDate,
          terms: termsDone,
          companyHeadName: companyHeadName,
          seal: sealImageSrcDone,
          sealUrl: sealImageUrl,
          businessRegistration: resistImageSrcDone,
          businessRegistrationUrl: resistImageUrl,
          data: error.response,
        });
        alert(`증명서 발급 통신을 실패하였습니다. 다시 시도해주세요.`);
        setIsLoading(false);
        console.log("Issue Certificate error", error);
      }
    } else {
      amplitude.track("발급 필수 정보 누락", {
        직장명: companyName,
        증명서종류: workDate.includes("현재") ? "재직증명서" : "경력증명서",
        약관동의: termsDone,
        대표자명: companyHeadName,
        직인: sealImageSrcDone,
        사업자등록증: resistImageSrcDone,
      });
      setIsErrorVisible(true);
    }
  };

  useEffect(() => {
    if (resistImageUrl) {
      setResistImageSrcDone(true);
    } else {
      setResistImageSrcDone(false);
    }

    if (sealImageUrl) {
      setSealImageSrcDone(true);
    } else {
      setSealImageSrcDone(false);
    }

    if (isRadio1Check && isRadio2Check && isRadio3Check) {
      amplitude.track("발급 필수 약관 동의", {
        증명서종류: workDate.includes("현재") ? "재직증명서" : "경력증명서",
        직장명: companyName,
      });
      setTermsDone(true);
    } else {
      setTermsDone(false);
    }

    if (!isRadio1Check || !isRadio2Check || !isRadio3Check || !isRadio4Check) {
      setIsRadioAllCheck(false);
    }
  }, [resistImageUrl, sealImageUrl, isRadio1Check, isRadio2Check, isRadio3Check, isRadio4Check, workDate, companyName]);

  useEffect(() => {
    if (sealImageSrc) {
      const imageUrl = URL.createObjectURL(sealImageSrc);
      setSealImageUrl(imageUrl);
    }
  }, [sealImageSrc]);

  useEffect(() => {
    if (resistImageSrc) {
      const imageUrl = URL.createObjectURL(resistImageSrc);
      setResistImageUrl(imageUrl);
    }
  }, [resistImageSrc]);

  const privacyPolicy = "https://certifie-privacy-policy.vercel.app"; //개인정보 처리방침
  const termsOfUse = "https://certifie-terms-of-use.vercel.app"; //이용약관

  const screenWidth = window.innerWidth;

  const handleDownloadSeal = () => {
    if (sealUrl.length > 0) {
      amplitude.track("생성 직인 이미지 다운로드");
      const link = document.createElement("a");
      link.href = sealUrl;
      link.download = `${sealText}_직인.png`;
      link.click();
      setSealText("");
      setSealUrl("");
      onCloseMakeSeal();
    } else {
      alert("직인 이미지를 생성해주세요.");
    }
  };

  return (
    <>
      {isLoading ? <LoadingData /> : null}
      {isErrorVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                {!termsDone ? <div className="Email_Sub_Error_Copy">- 필수 약관에 동의해주세요.</div> : null}
                {companyHeadName.length <= 0 ? <div className="Email_Sub_Error_Copy">- 기업/기관의 대표자 성함을 입력해주세요.</div> : null}
                {!sealImageSrcDone ? <div className="Email_Sub_Error_Copy">- 직인을 추가해주세요.</div> : null}
                {!resistImageSrcDone ? <div className="Email_Sub_Error_Copy">- 사업자 등록증을 추가해주세요.</div> : null}
              </div>
              <button className="Issue_Error_Button" onClick={onClickErrorClose}>
                <div className="Email_Right_Button_BG" style={{ marginLeft: "0px" }}>
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {isMakeSeal && (
        <div className="Signin_CompleteModal_BG">
          <div className="Make_Seal_Modal_CT">
            <div className="Make_Seal_Title_CT">
              <div className="Make_Seal_Title_Copy">직인 생성</div>
              <button className="Make_Seal_Icon_CT" onClick={onCloseMakeSeal}>
                <Close />
              </button>
            </div>
            <div className="Make_Seal_Divider" />
            <div>
              <div className="Make_Seal_Input_CT">
                <input
                  type="text"
                  value={sealText}
                  onChange={(e) => setSealText(e.target.value)}
                  placeholder="직인에 사용될 기업/기관명을 입력해주세요."
                  className="Make_Seal_Input"
                />
                <button onClick={generateSeal} className="Make_Seal_GenerateButton">
                  생성
                </button>
              </div>
              <div className="Make_Seal_Info_Copy">
                기업/기관명은 20자까지 입력할 수 있습니다. (한글, 영문, 숫자만 입력 가능)
                <br />
                ※직인의 서체가 깨진다면 '생성' 버튼을 한번 더 클릭해주세요.
              </div>

              <div className="Make_Seal_Canvas_CT">
                <canvas id="sealCanvas" ref={canvasRef} width="300" height="300" style={{ border: "1px solid rgb(170, 170, 170)" }}></canvas>
              </div>
              <div className="Make_Seal_Info_Copy">직인 다운로드를 하시면 직인 PNG 이미지가 다운로드됩니다.</div>
              <button ref={downloadLinkRef} className="Make_Seal_DownloadButton" onClick={handleDownloadSeal}>
                직인 다운로드
              </button>
            </div>
          </div>
        </div>
      )}
      {screenWidth > 1023 ? (
        <div className="AllContent_Between">
          <form className="Email_Issue_Content" onSubmit={handleIssueComplete} encType="multipart/form-data">
            <div className="Email_CT">
              <div className="Email_LeftArea_CT">
                <div className="Email_LeftContents_CT">
                  <div className="Email_Main_Copy_CT">
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_2_Copy">{name}님</div>
                      <div className="Email_Main_1_Copy">의</div>
                    </div>
                    <div className="Email_Main_1_Copy">경력증명서 내용을 확인해주세요.</div>
                  </div>
                  <div className="Email_Sub_Copy_CT">
                    <div className="Email_Sub_1_Copy">
                      아래 경력증명서 발급 약관 동의를 선택하신 후<br />{" "}
                      <span
                        style={{
                          fontWeight: "800",
                          color: "rgb(55, 105, 252)",
                        }}
                      >
                        '발급 완료'
                      </span>{" "}
                      버튼을 클릭해주세요.
                    </div>
                  </div>
                  <div className="Issue_Terms_CT">
                    <div className="TermLists_BG">
                      <TermList
                        bodyText={"필수동의 항목 및 광고성 정보 수신(선택)에 모두 동의합니다."}
                        twoLine
                        isCheckBox={isRadioAllCheck}
                        setIsCheckBox={setIsRadioAllCheck}
                        checkAll
                        checkAllHandler={checkAllHandler}
                      />
                      <div className="TermLists_Line" />
                      <TermList required bodyText={"만 15세 이상입니다."} isCheckBox={isRadio1Check} setIsCheckBox={setIsRadio1Check} />
                      <TermList required bodyText={"서비스 이용약관에 동의합니다."} detail detailLink={termsOfUse} isCheckBox={isRadio2Check} setIsCheckBox={setIsRadio2Check} />
                      <TermList
                        required
                        bodyText={"개인정보 수집 및 이용에 동의합니다."}
                        detail
                        detailLink={privacyPolicy}
                        isCheckBox={isRadio3Check}
                        setIsCheckBox={setIsRadio3Check}
                      />
                      <div className="TermLists_Line2" />
                      <TermList
                        optional
                        bodyText={"광고성 정보 이메일 수신에 동의합니다."}
                        detail
                        detailLink={privacyPolicy}
                        isCheckBox={isRadio4Check}
                        setIsCheckBox={setIsRadio4Check}
                      />
                    </div>
                    <div className="Email_RightArea_Buttons_CT">
                      <button className="Email_Button" style={{ marginTop: "0px" }} type="submit">
                        <div className="Email_Right_Button_BG" style={{ marginLeft: "0px" }}>
                          발급 완료
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <EmailProfile />
              </div>
              <div className="Email_RightArea_CT">
                <CertificatePDF
                  name={name}
                  birthday={birthday}
                  address={address}
                  workDate={workDate}
                  companyName={companyName}
                  telNumber={telNumber}
                  position={position}
                  department={department}
                  jobPosition={jobPosition}
                  jobList={jobList}
                  requestDate={requestDate}
                  requestNumber={requestNumber}
                  loseDate={loseDate}
                  issue
                  companyHeadName={companyHeadName}
                  setCompanyHeadName={setCompanyHeadName}
                  sealImageUrl={sealImageUrl}
                  onClickDeleteSealImage={onClickDeleteSealImage}
                  onClickSealImageUpload={onClickSealImageUpload}
                />
                <div className="Email_RightArea_Body_CT">
                  <div className="Email_RightArea_Resist_Copy" style={{ textAlign: "left" }}>
                    내용을 확인 하신 후 <span style={{ fontWeight: "700" }}>대표자명</span>과 <span style={{ fontWeight: "700" }}>직인</span>,
                    <span style={{ fontWeight: "700" }}>사업자등록증</span>을 추가해주세요.
                    <br />
                    <span style={{ color: "rgb(255, 115, 115)" }}>*직인은 png 확장자 파일만 등록 가능합니다.</span>
                  </div>
                </div>
                <div className="Issue_Resist_CT">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">직인</div>
                    <div className={sealImageSrcDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>

                  <div className="ProfileDetail_UploadButton_CT">
                    <input
                      className="ProfileDetail_ShortInput_BG"
                      style={{ width: "282px" }}
                      value={sealImageUrl ? sealImageInput.current.value.split("\\").pop().split("/").pop() : ""}
                      readOnly
                    />
                    <input
                      accept=".jpg, .jpeg, .png"
                      type="file"
                      onChange={(e) => onSealUpload(e)}
                      className="ProfileDetail_UploadButton_None"
                      ref={sealImageInput}
                      max-size="10485760"
                      name="seal"
                    />
                    <button type="button" className="ProfileDetail_UploadButton" onClick={onClickSealImageUpload}>
                      선택
                    </button>
                    <button type="button" className="ProfileDetail_UploadButton" onClick={onClickMakeSeal}>
                      만들기
                    </button>
                  </div>
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy" style={{ marginTop: "12px" }}>
                      사업자등록증
                    </div>
                    <div className={resistImageSrcDone ? "Required_Dot_Blue" : "Required_Dot_Red"} style={{ marginTop: "12px" }} />
                  </div>
                  <div className="ProfileDetail_UploadButton_CT">
                    <input className="ProfileDetail_ShortInput_BG " value={resistImageSrc ? resistImageSrc.name : ""} readOnly />
                    <input
                      accept=".pdf, .jpg, .jpeg, .png"
                      type="file"
                      onChange={(e) => onResistUpload(e)}
                      className="ProfileDetail_UploadButton_None"
                      ref={resistImageInput}
                      max-size="10485760"
                      name="resist"
                    />

                    <button type="button" className="ProfileDetail_UploadButton" onClick={onClickResistImageUpload}>
                      선택
                    </button>
                  </div>
                  <div className="Email_RightArea_Resist_Copy2">
                    개인정보보호법에 따라 사업자등록증은 사업자 검증 외 일체 사용되지 않으며,
                    <br />
                    직인 또한 경력증명서 발급을 위해서만 사용됩니다.
                  </div>
                </div>
              </div>
            </div>
          </form>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="AllContent_Between_Detail">
          <form className="Email_Issue_Content" onSubmit={handleIssueComplete} encType="multipart/form-data">
            <div className="Email_CT_Issue">
              <div className="Email_LeftArea_CT">
                <div className="Email_LeftContents_CT">
                  <div className="Email_Main_Copy_CT">
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_1_Copy">
                        <span
                          style={{
                            color: "rgb(55,105,252)",
                            fontWeight: "600",
                          }}
                        >
                          {name}님
                        </span>
                        의 경력증명서 내용을 확인해주세요.
                      </div>
                    </div>
                  </div>
                  <div className="Email_Sub_Copy_CT">
                    <div className="Email_Sub_1_Copy">
                      아래 경력증명서 내용을 확인 하신 후 <br />
                      <span
                        style={{
                          fontWeight: "800",
                          color: "rgb(55, 105, 252)",
                        }}
                      >
                        대표자 이름과 '직인' , '사업자등록증'
                      </span>{" "}
                      을 추가해주세요.
                    </div>
                    <div className="Email_Sub_1_Copy2">
                      개인정보보호법에 따라 사업자등록증은 사업자 검증 외 일체 사용되지 않으며, 직인 또한 경력증명서 발급을 위해서만 사용됩니다.
                    </div>
                  </div>
                </div>
              </div>
              <div className="Email_RightArea_CT2">
                <CertificatePDF
                  name={name}
                  birthday={birthday}
                  address={address}
                  workDate={workDate}
                  companyName={companyName}
                  telNumber={telNumber}
                  position={position}
                  department={department}
                  jobPosition={jobPosition}
                  jobList={jobList}
                  requestDate={requestDate}
                  requestNumber={requestNumber}
                  loseDate={loseDate}
                  issue
                  companyHeadName={companyHeadName}
                  setCompanyHeadName={setCompanyHeadName}
                  sealImageUrl={sealImageUrl}
                  onClickDeleteSealImage={onClickDeleteSealImage}
                  onClickSealImageUpload={onClickSealImageUpload}
                  mobile
                />
                <div className="Issue_Resist_CT">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">사업자등록증</div>
                    <div className={resistImageSrcDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <div className="ProfileDetail_UploadButton_CT">
                    <input className="ProfileDetail_ShortInput_BG " value={resistImageSrc ? resistImageSrc.name : ""} readOnly />
                    <input
                      accept=".pdf, .jpg, .jpeg, .png"
                      type="file"
                      onChange={(e) => onResistUpload(e)}
                      className="ProfileDetail_UploadButton_None"
                      ref={resistImageInput}
                      max-size="10485760"
                      name="resist"
                    />
                    <button type="button" className="ProfileDetail_UploadButton" onClick={onClickResistImageUpload}>
                      선택
                    </button>
                  </div>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) => onSealUpload(e)}
                    className="ProfileDetail_UploadButton_None"
                    ref={sealImageInput}
                    max-size="10485760"
                    name="seal"
                  />
                </div>
                <div className="Email_RightArea_Body_CT">
                  <div className="Email_RightArea_Resist_Copy">
                    아래 동의 내용을 선택하신 후 <span style={{ fontWeight: "700" }}>'발급 완료'</span> 버튼을 눌러주세요.
                  </div>
                </div>
                <div className="Issue_Terms_CT">
                  <div className="TermLists_Short_BG_Issue">
                    <TermList
                      bodyText={"동의 항목에 모두 동의합니다."}
                      twoLine
                      isCheckBox={isRadioAllCheck}
                      setIsCheckBox={setIsRadioAllCheck}
                      checkAll
                      checkAllHandler={checkAllHandler}
                      blue
                      shortWidth
                      issue
                      margin={"8px"}
                    />
                    <div className="TermLists_Line2" />
                    <TermList required bodyText={"만 15세 이상입니다."} isCheckBox={isRadio1Check} setIsCheckBox={setIsRadio1Check} black shortWidth issue margin={"2px"} />
                    <TermList
                      required
                      bodyText={"서비스 이용약관에 동의합니다."}
                      detail
                      detailLink={termsOfUse}
                      isCheckBox={isRadio2Check}
                      setIsCheckBox={setIsRadio2Check}
                      black
                      shortWidth
                      issue
                      margin={"2px"}
                    />
                    <TermList
                      required
                      bodyText={"개인정보 수집 및 이용에 동의합니다."}
                      detail
                      detailLink={privacyPolicy}
                      isCheckBox={isRadio3Check}
                      setIsCheckBox={setIsRadio3Check}
                      black
                      shortWidth
                      issue
                      margin={"2px"}
                    />
                    <TermList
                      optional
                      bodyText={"광고성 정보 이메일 수신에 동의합니다."}
                      detail
                      detailLink={privacyPolicy}
                      isCheckBox={isRadio4Check}
                      setIsCheckBox={setIsRadio4Check}
                      black
                      shortWidth
                      issue
                      margin={"2px"}
                    />
                  </div>

                  <div className="Email_RightArea_Buttons_CT">
                    <button className="Email_Button" style={{ marginTop: "0px" }} type="submit">
                      <div className="Email_Right_Button_BG" style={{ marginLeft: "0px" }}>
                        발급 완료
                      </div>
                    </button>
                  </div>
                </div>
                <EmailProfile />
              </div>
            </div>
          </form>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
});

export default RQ03RequestProgress;
