const CheckBoxBlank = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.95 20.5C4.55 20.5 4.20833 20.3583 3.925 20.075C3.64167 19.7917 3.5 19.45 3.5 19.05V4.95C3.5 4.55 3.64167 4.20833 3.925 3.925C4.20833 3.64167 4.55 3.5 4.95 3.5H19.05C19.45 3.5 19.7917 3.64167 20.075 3.925C20.3583 4.20833 20.5 4.55 20.5 4.95V19.05C20.5 19.45 20.3583 19.7917 20.075 20.075C19.7917 20.3583 19.45 20.5 19.05 20.5H4.95ZM4.95 19.375H19.05C19.1333 19.375 19.2083 19.3417 19.275 19.275C19.3417 19.2083 19.375 19.1333 19.375 19.05V4.95C19.375 4.86667 19.3417 4.79167 19.275 4.725C19.2083 4.65833 19.1333 4.625 19.05 4.625H4.95C4.86667 4.625 4.79167 4.65833 4.725 4.725C4.65833 4.79167 4.625 4.86667 4.625 4.95V19.05C4.625 19.1333 4.65833 19.2083 4.725 19.275C4.79167 19.3417 4.86667 19.375 4.95 19.375Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default CheckBoxBlank;
