import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import { observer } from "mobx-react";
import React from "react";
import InitialLogo from "../Assets/Logo/InitialLogo";
import CertedLogo from "../Assets/Logo/CertedLogo";
import * as amplitude from "@amplitude/analytics-browser";

const GNB = observer(() => {
  const screenWidth = window.innerWidth;
  const landingPageUrl = "https://www.certifie.io";
  const handleCertifie = () => {
    amplitude.track("랜딩페이지 진입");
    window.open(landingPageUrl, "_blank", "noopener noreferrer");
  };

  return (
    <div className="GNB_BG">
      <div className="GNB_LeftContents">
        <div className="GNB_Logout_CT">
          <button className="GNB_Logout_Button" onClick={handleCertifie}>
            <div className="GNB_Logo">{screenWidth >= 768 ? <CertedLogo /> : <InitialLogo />}</div>
          </button>
        </div>
      </div>
      <div className="GNB_RightContents"></div>
    </div>
  );
});

export default GNB;
